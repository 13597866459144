import './App.css';
import KinhDichVanVuong from './containers/KinhDichVanVuong/KinhDichVanVuong';

function App() {

  return (
    <>
      <KinhDichVanVuong />
    </>
  );
}

export default App;
