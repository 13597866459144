import { CON_GIAP } from "../constants/constants"

const ListNguyetPha = [
    {
        thangCanChi: CON_GIAP.MUI, nguyetPha: CON_GIAP.SUU
    },
    {
        thangCanChi: CON_GIAP.SUU, nguyetPha: CON_GIAP.MUI
    },
    {
        thangCanChi: CON_GIAP.THAN, nguyetPha: CON_GIAP.DAN
    },
    {
        thangCanChi: CON_GIAP.DAN, nguyetPha: CON_GIAP.THAN
    },
    {
        thangCanChi: CON_GIAP.TYJ, nguyetPha: CON_GIAP.HOI
    },
    {
        thangCanChi: CON_GIAP.HOI, nguyetPha: CON_GIAP.TYJ
    },
    {
        thangCanChi: CON_GIAP.TYS, nguyetPha: CON_GIAP.NGO
    },
    {
        thangCanChi: CON_GIAP.NGO, nguyetPha: CON_GIAP.TYS
    },
    {
        thangCanChi: CON_GIAP.MAO, nguyetPha: CON_GIAP.DAU
    },
    {
        thangCanChi: CON_GIAP.DAU, nguyetPha: CON_GIAP.MAO
    },
    {
        thangCanChi: CON_GIAP.THIN, nguyetPha: CON_GIAP.TUAT
    },
    {
        thangCanChi: CON_GIAP.TUAT, nguyetPha: CON_GIAP.THIN
    },
];

export default ListNguyetPha