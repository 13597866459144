import firebase from "firebase/app";
import "firebase/auth"; // Import Firebase Authentication

// Cấu hình Firebase (lấy từ Firebase Console)
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  // For databases not in the us-central1 location, databaseURL will be of the
  // form https://[databaseName].[region].firebasedatabase.app.
  // For example, https://your-database-123.europe-west1.firebasedatabase.app
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  projectId: process.env.REACT_APP_PROJECT_ID,
};

// Khởi tạo Firebase App
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Khởi tạo Firebase Auth
const auth = firebase.auth();

export { auth };
