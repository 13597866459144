import React from "react";
import { GoogleLogout } from "react-google-login";
import { auth } from "../firebaseConfig";

const Logout = (props) => {
  const logout = () => {
    localStorage.removeItem("profile");
    localStorage.removeItem("isLoggedIn");
    props.getProfile({});
    auth
      .signOut()
      .then(() => {
        console.log("User logged out successfully.");
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  };

  return (
    <div className="dropdown-content">
      <GoogleLogout
        clientId={process.env.REACT_APP_GOOGLE_ID}
        buttonText="Đăng xuất"
        onLogoutSuccess={logout}
        className="google-logout"
      ></GoogleLogout>
    </div>
  );
};

export default Logout;
