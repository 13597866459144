
import { CON_GIAP, TEN_CAN } from "../constants/constants"

const NgayCanChi = [
    { no: 1, nameCanChi: 'Giáp Tý', can: TEN_CAN.GIAP,chi: CON_GIAP.TYS, canChiForHour: 'Giáp Kỷ' ,canChi: "GIAP_TY"},
    { no: 2, nameCanChi: 'Ất Sửu', can: TEN_CAN.AT, chi: CON_GIAP.SUU, canChiForHour: 'Giáp Kỷ' ,canChi: "AT_SUU"},
    { no: 3, nameCanChi: 'Bính Dần', can: TEN_CAN.BINH, chi: CON_GIAP.DAN, canChiForHour: 'Giáp Kỷ' ,canChi: "BINH_DAN"},
    { no: 4, nameCanChi: 'Đinh Mão', can: TEN_CAN.DINH, chi: CON_GIAP.MAO, canChiForHour: 'Giáp Kỷ' ,canChi: "DINH_MAO"},
    { no: 5, nameCanChi: 'Mậu Thìn', can: TEN_CAN.MAU, chi: CON_GIAP.THIN, canChiForHour: 'Giáp Kỷ' ,canChi: "MAU_THIN"},
    { no: 6, nameCanChi: 'Kỷ Tỵ', can: TEN_CAN.KY, chi: CON_GIAP.TYJ, canChiForHour: 'Giáp Kỷ' ,canChi: "KY_TY"},
    { no: 7, nameCanChi: 'Canh Ngọ', can: TEN_CAN.CANH, chi: CON_GIAP.NGO, canChiForHour: 'Giáp Kỷ' ,canChi: "CANH_NGO"},
    { no: 8, nameCanChi: 'Tân Mùi', can: TEN_CAN.TAN, chi: CON_GIAP.MUI, canChiForHour: 'Giáp Kỷ' ,canChi: "TAN_MUI"},
    { no: 9, nameCanChi: 'Nhâm Thân', can: TEN_CAN.NHAM, chi: CON_GIAP.THAN, canChiForHour: 'Giáp Kỷ' ,canChi: "NHAM_THAN"},
    { no: 10, nameCanChi: 'Quý Dậu', can: TEN_CAN.QUY, chi: CON_GIAP.DAU, canChiForHour: 'Giáp Kỷ' ,canChi: "QUY_DAU"},
    { no: 11, nameCanChi: 'Giáp Tuất', can: TEN_CAN.GIAP, chi: CON_GIAP.TUAT, canChiForHour: 'Giáp Kỷ' ,canChi: "GIAP_TUAT"},
    { no: 12, nameCanChi: 'Ất Hợi', can: TEN_CAN.AT, chi: CON_GIAP.HOI, canChiForHour: 'Giáp Kỷ' ,canChi: "AT_HOI"},
    { no: 13, nameCanChi: 'Bính Tý', can: TEN_CAN.BINH, chi: CON_GIAP.TYS, canChiForHour: 'Ất Canh' ,canChi: "BINH_TY"},
    { no: 14, nameCanChi: 'Đinh Sửu', can: TEN_CAN.DINH, chi: CON_GIAP.SUU, canChiForHour: 'Ất Canh' ,canChi: "DINH_SUU"},
    { no: 15, nameCanChi: 'Mậu Dần', can: TEN_CAN.MAU, chi: CON_GIAP.DAN, canChiForHour: 'Ất Canh' ,canChi: "MAU_DAN"},
    { no: 16, nameCanChi: 'Kỷ Mão', can: TEN_CAN.KY, chi: CON_GIAP.MAO, canChiForHour: 'Ất Canh' ,canChi: "KY_MAO"},
    { no: 17, nameCanChi: 'Canh Thìn', can: TEN_CAN.CANH, chi: CON_GIAP.THIN, canChiForHour: 'Ất Canh' ,canChi: "CANH_THIN"},
    { no: 18, nameCanChi: 'Tân Tỵ', can: TEN_CAN.TAN, chi: CON_GIAP.TYJ, canChiForHour: 'Ất Canh' ,canChi: "TAN_TY"},
    { no: 19, nameCanChi: 'Nhâm Ngọ', can: TEN_CAN.NHAM, chi: CON_GIAP.NGO, canChiForHour: 'Ất Canh' ,canChi: "NHAM_NGO"},
    { no: 20, nameCanChi: 'Quý Mùi', can: TEN_CAN.QUY, chi: CON_GIAP.MUI, canChiForHour: 'Ất Canh' ,canChi: "QUY_MUI"},
    { no: 21, nameCanChi: 'Giáp Thân', can: TEN_CAN.GIAP, chi: CON_GIAP.THAN, canChiForHour: 'Ất Canh' ,canChi: "GIAP_THAN"},
    { no: 22, nameCanChi: 'Ất Dậu', can: TEN_CAN.AT, chi: CON_GIAP.DAU, canChiForHour: 'Ất Canh' ,canChi: "AT_DAU"},
    { no: 23, nameCanChi: 'Bính Tuất', can: TEN_CAN.BINH, chi: CON_GIAP.TUAT, canChiForHour: 'Ất Canh' ,canChi: "BINH_TUAT"},
    { no: 24, nameCanChi: 'Đinh Hợi', can: TEN_CAN.DINH, chi: CON_GIAP.HOI, canChiForHour: 'Ất Canh' ,canChi: "DINH_HOI"},
    { no: 25, nameCanChi: 'Mậu Tý', can: TEN_CAN.MAU, chi: CON_GIAP.TYS, canChiForHour: 'Bính Tân' ,canChi: "MAU_TY"},
    { no: 26, nameCanChi: 'Kỷ Sửu', can: TEN_CAN.KY, chi: CON_GIAP.SUU, canChiForHour: 'Bính Tân' ,canChi: "KY_SUU"},
    { no: 27, nameCanChi: 'Canh Dần', can: TEN_CAN.CANH, chi: CON_GIAP.DAN, canChiForHour: 'Bính Tân' ,canChi: "CANH_DAN"},
    { no: 28, nameCanChi: 'Tân Mão', can: TEN_CAN.TAN, chi: CON_GIAP.MAO, canChiForHour: 'Bính Tân' ,canChi: "TAN_MAO"},
    { no: 29, nameCanChi: 'Nhâm Thìn', can: TEN_CAN.NHAM, chi: CON_GIAP.THIN, canChiForHour: 'Bính Tân' ,canChi: "NHAM_THIN"},
    { no: 30, nameCanChi: 'Quý Tỵ', can: TEN_CAN.QUY, chi: CON_GIAP.TYJ, canChiForHour: 'Bính Tân' ,canChi: "QUY_TY"},
    { no: 31, nameCanChi: 'Giáp Ngọ', can: TEN_CAN.GIAP, chi: CON_GIAP.NGO, canChiForHour: 'Bính Tân' ,canChi: "GIAP_NGO"},
    { no: 32, nameCanChi: 'Ất Mùi', can: TEN_CAN.AT, chi: CON_GIAP.MUI, canChiForHour: 'Bính Tân' ,canChi: "AT_MUI"},
    { no: 33, nameCanChi: 'Bính Thân', can: TEN_CAN.BINH, chi: CON_GIAP.THAN, canChiForHour: 'Bính Tân' ,canChi: "BINH_THAN"},
    { no: 34, nameCanChi: 'Đinh Dậu', can: TEN_CAN.DINH, chi: CON_GIAP.DAU, canChiForHour: 'Bính Tân' ,canChi: "DINH_DAU"},
    { no: 35, nameCanChi: 'Mậu Tuất', can: TEN_CAN.MAU, chi: CON_GIAP.TUAT, canChiForHour: 'Bính Tân' ,canChi: "MAU_TUAT"},
    { no: 36, nameCanChi: 'Kỷ Hợi', can: TEN_CAN.KY, chi: CON_GIAP.HOI, canChiForHour: 'Bính Tân' ,canChi: "KY_HOI"},
    { no: 37, nameCanChi: 'Canh Tý', can: TEN_CAN.CANH, chi: CON_GIAP.TYS, canChiForHour: 'Đinh Nhâm' ,canChi: "CANH_TY"},
    { no: 38, nameCanChi: 'Tân Sửu', can: TEN_CAN.TAN, chi: CON_GIAP.SUU, canChiForHour: 'Đinh Nhâm' ,canChi: "TAN_SUU"},
    { no: 39, nameCanChi: 'Nhâm Dần', can: TEN_CAN.NHAM, chi: CON_GIAP.DAN, canChiForHour: 'Đinh Nhâm' ,canChi: "NHAM_DAN"},
    { no: 40, nameCanChi: 'Quý Mão', can: TEN_CAN.QUY, chi: CON_GIAP.MAO, canChiForHour: 'Đinh Nhâm' ,canChi: "QUY_MAO"},
    { no: 41, nameCanChi: 'Giáp Thìn', can: TEN_CAN.GIAP, chi: CON_GIAP.THIN, canChiForHour: 'Đinh Nhâm' ,canChi: "GIAP_THIN"},
    { no: 42, nameCanChi: 'Ất Tỵ', can: TEN_CAN.AT, chi: CON_GIAP.TYJ, canChiForHour: 'Đinh Nhâm' ,canChi: "AT_TY"},
    { no: 43, nameCanChi: 'Bính Ngọ', can: TEN_CAN.BINH, chi: CON_GIAP.NGO, canChiForHour: 'Đinh Nhâm' ,canChi: "BINH_NGO"},
    { no: 44, nameCanChi: 'Đinh Mùi', can: TEN_CAN.DINH, chi: CON_GIAP.MUI, canChiForHour: 'Đinh Nhâm' ,canChi: "DINH_MUI"},
    { no: 45, nameCanChi: 'Mậu Thân', can: TEN_CAN.MAU, chi: CON_GIAP.THAN, canChiForHour: 'Đinh Nhâm' ,canChi: "MAU_THAN"},
    { no: 46, nameCanChi: 'Kỷ Dậu', can: TEN_CAN.KY, chi: CON_GIAP.DAU, canChiForHour: 'Đinh Nhâm' ,canChi: "KY_DAU"},
    { no: 47, nameCanChi: 'Canh Tuất', can: TEN_CAN.CANH, chi: CON_GIAP.TUAT, canChiForHour: 'Đinh Nhâm' ,canChi: "CANH_TUAT"},
    { no: 48, nameCanChi: 'Tân Hợi', can: TEN_CAN.TAN, chi: CON_GIAP.HOI, canChiForHour: 'Đinh Nhâm' ,canChi: "TAN_HOI"},
    { no: 49, nameCanChi: 'Nhâm Tý', can: TEN_CAN.NHAM, chi: CON_GIAP.TYS, canChiForHour: 'Mậu Quý' ,canChi: "NHAM_TY"},
    { no: 50, nameCanChi: 'Quý Sửu', can: TEN_CAN.QUY, chi: CON_GIAP.SUU, canChiForHour: 'Mậu Quý' ,canChi: "QUY_SUU"},
    { no: 51, nameCanChi: 'Giáp Dần', can: TEN_CAN.GIAP, chi: CON_GIAP.DAN, canChiForHour: 'Mậu Quý' ,canChi: "GIAP_DAN"},
    { no: 52, nameCanChi: 'Ất Mão', can: TEN_CAN.AT, chi: CON_GIAP.MAO, canChiForHour: 'Mậu Quý' ,canChi: "AT_MAO"},
    { no: 53, nameCanChi: 'Bính Thìn', can: TEN_CAN.BINH, chi: CON_GIAP.THIN, canChiForHour: 'Mậu Quý' ,canChi: "BINH_THIN"},
    { no: 54, nameCanChi: 'Đinh Tỵ', can: TEN_CAN.DINH, chi: CON_GIAP.TYJ, canChiForHour: 'Mậu Quý' ,canChi: "DINH_TY"},
    { no: 55, nameCanChi: 'Mậu Ngọ', can: TEN_CAN.MAU, chi: CON_GIAP.NGO, canChiForHour: 'Mậu Quý' ,canChi: "MAU_NGO"},
    { no: 56, nameCanChi: 'Kỷ Mùi', can: TEN_CAN.KY, chi: CON_GIAP.MUI, canChiForHour: 'Mậu Quý' ,canChi: "KY_MUI"},
    { no: 57, nameCanChi: 'Canh Thân', can: TEN_CAN.CANH, chi: CON_GIAP.THAN, canChiForHour: 'Mậu Quý' ,canChi: "CANH_THAN"},
    { no: 58, nameCanChi: 'Tân Dậu', can: TEN_CAN.TAN, chi: CON_GIAP.DAU, canChiForHour: 'Mậu Quý' ,canChi: "TAN_DAU"},
    { no: 59, nameCanChi: 'Nhâm Tuất', can: TEN_CAN.NHAM, chi: CON_GIAP.TUAT, canChiForHour: 'Mậu Quý' ,canChi: "NHAM_TUAT"},
    { no: 60, nameCanChi: 'Quý Hợi', can: TEN_CAN.QUY, chi: CON_GIAP.HOI, canChiForHour: 'Mậu Quý' ,canChi: "QUY_HOI"},
]

export default NgayCanChi