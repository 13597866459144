import { faPen, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment-mini";
import React, { useEffect, useState } from "react";
import ListQueKinhDich from "../../common/QueKinhDich";
import DefaultFooter from "../../components/DefaultFooter";
import DefaultHeader from "../../components/DefaultHeader";
import KetLuanPhanTich from "../../components/KetLuanPhanTich";
import LapQueDich from "../../components/LapQueDich";
import LucHao from "../../components/LucHao";
import Login from "../Login";
import Logout from "../Logout";
import firebase from 'firebase'

const KinhDichVanVuong = (props) => {
    const [queKinhDich, setQueKinhDich] = useState(ListQueKinhDich[0])
    const [queHaoDong, setQueHaoDong] = useState({})
    const [ngayCanChi, setNgayCanChi] = useState({})
    const [khongVong, setKhongVong] = useState("")
    const [nguyetPha, setNguyetPha] = useState("")
    const [visibleDropdown, setVisibleDropdown] = useState(false)
    const [visibleInputCode, setVisibleInputCode] = useState(false)
    const [code, setCode] = useState('')
    const [profile, setProfile] = useState((localStorage.profile && JSON.parse(localStorage.profile)) || {})

    const getQue = (object, setStateKey) => {
        setStateKey(object)
        setVisibleDropdown(false)
    }

    const sendCode = () => {
        let db = firebase.firestore()
        if (code) {
            let redeem_code = db.collection("redeem_code").doc("Ac2eWZZm0iE0N5fc5WBQ")

            redeem_code.get().then((doc) => {
                let arr = doc.data();

                // chỗ này kiểm tra code user nhập đã từng đc sử dụng chưa ?
                if (arr?.[code] && arr?.[code] == true) { // code chưa đc sử dụng
                    // lưu local thông tin user login để hiển thị nội dung web
                    delete profile.exprireDate
                    getQue(profile, setProfile)
                    localStorage.setItem("profile", profile && JSON.stringify(profile))

                    // update isActive = false để không cần nhập code lần nữa
                    redeem_code.set({
                        [code]: false
                    }, { merge: true });

                    // update ngày hết hạn của user là 100 năm từ ngày hôm nay và lưu code vào thông tin user
                    db.collection("User_KinhDich").doc(profile?.email).set({
                        exprireDate: moment().add(100, 'years').toDate(),
                        code: code
                    }, { merge: true });

                    setVisibleInputCode(false)
                    alert("Nhập code thành công. Bạn có thể tiếp tục sử dụng trang web")
                } else { // code đã đc user khác sử dụng rồi
                    alert("Code không hợp lệ. Vui lòng nhập code khác!")
                }
            });
        }
    }

    return (
        <>
            {(Object.keys(profile).length == 0 || !localStorage.isLoggedIn)
                ? <Login getProfile={(object) => getQue(object, setProfile)} />
                : <div className="flex-column">
                    <DefaultHeader />
                    <div className="div-padding-top text-right">
                        {profile?.name &&
                            <>
                                {profile?.exprireDate &&
                                    <strong>Ngày hết hạn sử dụng web miễn phí : {profile?.exprireDate && moment(profile?.exprireDate).format("DD/MM/YYYY") || ""}</strong>
                                }
                                <div className="dropdown ml-2" onClick={() => setVisibleDropdown(!visibleDropdown)}>
                                    <span>Xin chào {profile?.name} <FontAwesomeIcon className="mb-1 ml-2" icon={faSortDown} /></span>
                                    {visibleDropdown &&
                                        <div className="dropdown-content flex-column">
                                            {profile?.exprireDate &&
                                                <p className="mb-0" onClick={() => setVisibleInputCode(true)}><FontAwesomeIcon className="ml-1 mr-3" icon={faPen} /> Nhập code</p>
                                            }
                                            <Logout getProfile={(object) => getQue(object, setProfile)} />
                                        </div>
                                    }
                                </div>
                            </>
                        }

                        {visibleInputCode &&
                            <div className="custom-modal">
                                <div className="modal-content w-100">
                                    <div className="modal-header d-flex justify-content-between text-left">
                                        <p className="mb-0 font-weight-bold">Vui lòng nhập code và nhấn nút "Gửi code" để có thể sử dụng website</p>
                                        <button type="button" className="close" onClick={() => setVisibleInputCode(false)}>&times;</button>
                                    </div>
                                    <div className="modal-body text-left">
                                        <input placeholder="Code" className="px-1 mr-2" value={code} onChange={e => setCode(e.target.value)} />
                                    </div>
                                    <div className="modal-footer">
                                        <button onClick={e => sendCode(e.target.value)} className="btn-blue" disabled={code?.length == 0} style={{ cursor: code?.length == 0 ? `not-allowed` : `pointer` }}>Gửi code</button>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="div-background">
                            <LapQueDich
                                getQueKinhDich={(object) => getQue(object, setQueKinhDich)}
                                getQueHaoDong={(object) => getQue(object, setQueHaoDong)}
                                getNgayCanChi={(object) => getQue(object, setNgayCanChi)}
                                getKhongVong={(string) => getQue(string, setKhongVong)}
                                getNguyetPha={(string) => getQue(string, setNguyetPha)}
                            />

                            <LucHao
                                queKinhDich={queKinhDich}
                                queHaoDong={queHaoDong}
                                ngayCanChi={ngayCanChi}
                                khongVong={khongVong}
                                nguyetPha={nguyetPha}
                                getQueKinhDich={(object) => getQue(object, setQueKinhDich)}
                                getQueHaoDong={(object) => getQue(object, setQueHaoDong)}
                            />

                            <KetLuanPhanTich
                                queKinhDich={queKinhDich}
                            />
                        </div>
                    </div>

                    <DefaultFooter />
                </div>
            }
        </>
    )
}

export default KinhDichVanVuong