
const TYS = 'Tý';
const SUU = 'Sửu';
const DAN = 'Dần';
const MAO = 'Mão';
const THIN = 'Thìn';
const TYJ = 'Tỵ';
const NGO = 'Ngọ';
const MUI = 'Mùi';
const THAN = 'Thân';
const DAU = 'Dậu';
const TUAT = 'Tuất';
const HOI = 'Hợi';

const CON_GIAP = { TYS, SUU, DAN, MAO, THIN, TYJ, NGO, MUI, THAN, DAU, TUAT, HOI }

const AT = 'Ất';
const BINH = 'Bính';
const DINH = 'Đinh';
const MAU = 'Mậu';
const KY = 'Kỷ';
const CANH = 'Canh';
const GIAP = 'Giáp';
const NHAM = 'Nhâm';
const TAN = 'Tân';
const QUY = 'Quý';

const TEN_CAN = { GIAP, AT, BINH, DINH, MAU, KY, CANH, TAN, NHAM, QUY }

const TEN_LUC_THU = ["Thanh Long", "Chu Tước", "Câu Trần", "Đằng Xà", "Bạch Hổ", "Huyền Vũ"];

export {
    CON_GIAP,
    TEN_CAN,
    TEN_LUC_THU
}