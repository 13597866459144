const ListQueKinhDich = [
    {
        position: 1,
        tenque: 'CÀN VI THIÊN',
        nguhanhgiadinhquai: 'KIM',
        huyenkhongnguhanh: 9,
        huyenkhongquaivan: 1,
        thequai: 'BÁT THUẦN QUÁI',
        hoagiap: 'GIÁP NGỌ',
        haothe: 6,
        haoung: 3,
        diachiquai1: 'Phụ Mẫu',
        diachiquai2: 'Tuất',
        diachiquai3: 'Huynh Đệ',
        diachiquai4: 'Thân',
        diachiquai5: 'Quan Quỷ',
        diachiquai6: 'Ngọ',
        diachiquai7: 'Phụ Mẫu',
        diachiquai8: 'Thìn',
        diachiquai9: 'Thê Tài',
        diachiquai10: 'Dần',
        diachiquai11: 'Tử Tôn',
        diachiquai12: 'Tý',
        CodeQuai: 'Lien-Lien-Lien-Lien-Lien-Lien',
        note1: 'Thể',
        note4: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Tốt nhất là đi về phía vùng nắng ấm, phía Nam hoặc Tây Bắc.<br><strong>SỰ NGHIỆP:</strong> Hãy hành động mạnh mẽ khi cơ hội gõ cửa. Vận may đã đến!<br><strong>TÀI LỘC:</strong> Sẽ có tiền trong những ngày sắp tới, có thể là một số tiền khá lớn.<br><strong>KINH DOANH:</strong> Bạn có lợi thế rất lớn, tốt nhất là hãy nắm bắt cơ hội.<br><strong>TÌNH CẢM:</strong> Hãy tiếp cận mọi việc theo cách nhẹ nhàng mềm mỏng.<br><strong>HỌC HÀNH:</strong> Hãy tập trung học hành chăm chỉ, bạn có khả năng cao đạt thành tích<br><strong>SINH CON:</strong> Dễ sinh con trai, quý tử nếu vào năm Mùi, Tuất.<br><strong>KIỆN TỤNG:</strong> Có quý nhân giúp đỡ, nhưng nếu kiện tụng mùa đông thì vẫn bất lợi.<br><strong>MỘ PHẦN:</strong> Nếu phần mộ toạ Tây Bắc hay toạ Đông Nam thì con cháu hưởng phúc.<br><strong>NHÀ Ở:</strong> Cần tìm nhà Tây Tứ Trạch - Tây Bắc hoặc Tây Nam là lý tưởng nhất.<br>'
    },
    {
        position: 2,
        tenque: 'KHÔN VỊ ĐỊA',
        nguhanhgiadinhquai: 'THỔ',
        huyenkhongnguhanh: 1,
        huyenkhongquaivan: 1,
        thequai: 'BÁT THUẦN QUÁI',
        hoagiap: 'GIÁP TÝ',
        haothe: 6,
        haoung: 3,
        diachiquai1: 'Tử Tôn',
        diachiquai2: 'Dậu',
        diachiquai3: 'Thê Tài',
        diachiquai4: 'Hợi',
        diachiquai5: 'Huynh Đệ',
        diachiquai6: 'Sửu',
        diachiquai7: 'Quan Quỷ',
        diachiquai8: 'Mão',
        diachiquai9: 'Phụ Mẫu',
        diachiquai10: 'Tỵ',
        diachiquai11: 'Huynh Đệ',
        diachiquai12: 'Mùi',
        CodeQuai: 'Dut-Dut-Dut-Dut-Dut-Dut',
        note1: 'Thể',
        note4: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Xuất hành thuận lợi, tuy nhiên hãy đi chậm, và bằng đường bộ.<br><strong>SỰ NGHIỆP:</strong> Hãy tập trung vào những vấn đề thiết yếu thay vì mơ mộng cao.<br><strong>TÀI LỘC:</strong> Hãy quan sát tình hình thật cẩn thận. Thành công tuy chậm nhưng bền.<br><strong>KINH DOANH:</strong> Những tin tức có vẻ xấu ban đầu lại biến thành tin tốt về sau.<br><strong>TÌNH CẢM:</strong> Hãy tiến chậm và từ tốn chinh phục nửa kia. Tránh quan hệ mờ ám.<br><strong>HỌC HÀNH:</strong> Việc học cần phải tiến hành từ gốc. Tập trung vào kiến thức căn bản.'
    },
    {
        position: 3,
        tenque: 'THỦY LÔI TRUÂN',
        nguhanhgiadinhquai: 'THỦY',
        huyenkhongnguhanh: 7,
        huyenkhongquaivan: 4,
        thequai: 'NHỊ THẾ QUÁI',
        hoagiap: 'MẬU TÝ',
        haothe: 2,
        haoung: 5,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Tý',
        diachiquai3: 'Quan Quỷ',
        diachiquai4: 'Tuất',
        diachiquai5: 'Phụ Mẫu',
        diachiquai6: 'Thân',
        diachiquai7: 'Quan Quỷ',
        diachiquai8: 'Thìn',
        diachiquai9: 'Tử Tôn',
        diachiquai10: 'Dần',
        diachiquai11: 'Huynh Đệ',
        diachiquai12: 'Tý',
        CodeQuai: 'Dut-Lien-Dut-Dut-Dut-Lien',
        note5: 'Thể',
        note2: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Đi xa nhất là bằng đường thuỷ, dễ gặp trở ngại, mất giấy tờ.<br><strong>SỰ NGHIỆP:</strong> Bạn có nhiều tham vọng lớn, nhưng thời điểm chưa chín mùi.<br><strong>TÀI LỘC:</strong> Hãy đánh giá lại tất cả mọi cơ hội đầu tư. Rủi ro dễ mất tiền.<br><strong>KINH DOANH:</strong> Cần bình tĩnh suy nghĩ giải pháp, tìm quý nhân hợp tuổi mình.<br><strong>TÌNH CẢM:</strong> Có nhiều tin đồn làm ảnh hưởng đến tình cảm tốt đẹp của bạn.<br><strong>HỌC HÀNH:</strong> Đừng để sức ép học tập làm bạn nản chí! Việc học cần có sự say mê.<br><strong>SỨC KHỎE: </strong>Có thể bạn sắp bị bệnh liên quan đến tiết niệu, gan và thận.<br><strong>SINH CON:</strong> Trai gái đầy đủ.<br><strong>NHÀ CỬA:</strong> Nhà hướng Đông Nam, Đông tốt. Nên nhập trạch trong mùa xuân.<br><strong>MỘ PHẦN:</strong> Mộ hướng Đông hoặc Đông Nam sẽ tốt cho con cháu.'
    },
    {
        position: 4,
        tenque: 'SƠN THỦY MÔNG',
        nguhanhgiadinhquai: 'HỎA',
        huyenkhongnguhanh: 6,
        huyenkhongquaivan: 2,
        thequai: 'TỨ THẾ QUÁI',
        hoagiap: 'CANH THÂN',
        haothe: 4,
        haoung: 1,
        diachiquai1: 'Phụ Mẫu',
        diachiquai2: 'Dần',
        diachiquai3: 'Quan Quỷ',
        diachiquai4: 'Tý',
        diachiquai5: 'Tử Tôn',
        diachiquai6: 'Tuất',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Ngọ',
        diachiquai9: 'Tử Tôn',
        diachiquai10: 'Thìn',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Dần',
        CodeQuai: 'Lien-Dut-Dut-Dut-Lien-Dut',
        note3: 'Thể',
        note6: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Đi du lịch rất có ích cho tuổi trẻ để trưởng thành hơn lên.<br><strong>SỰ NGHIỆP:</strong> Nên tham khảo hỏi qua ý kiến của cấp trên hoặc người lớn tuổi.<br><strong>TÀI LỘC:</strong> Tránh đầu cơ và tin vào những lời đồn vô căn cứ trên thị trường.<br><strong>KINH DOANH:</strong> Sự ngây thơ của bạn có thể khiến bạn mất tiền. Tính toán thật kỹ!<br><strong>TÌNH CẢM:</strong> Rắc rối trong giao tiếp có thể lẫn đến mất lòng tin.<br><strong>HỌC HÀNH:</strong> Tiến trình học tập chậm chạp do học sai phương pháp.<br><strong>SỨC KHỎE: </strong>Một vài bệnh không dễ phát hiện. Cẩn trọng tiêu hoá, tiết niệu.'
    },
    {
        position: 5,
        tenque: 'THỦY THIÊN NHU',
        nguhanhgiadinhquai: 'THỔ',
        huyenkhongnguhanh: 7,
        huyenkhongquaivan: 3,
        thequai: 'DU HỒN QUÁI',
        hoagiap: 'ẤT TỴ',
        haothe: 4,
        haoung: 1,
        diachiquai1: 'Thê Tài',
        diachiquai2: 'Tý',
        diachiquai3: 'Huynh Đệ',
        diachiquai4: 'Tuất',
        diachiquai5: 'Tử Tôn',
        diachiquai6: 'Thân',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Thìn',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Dần',
        diachiquai11: 'Thê Tài',
        diachiquai12: 'Tý',
        CodeQuai: 'Dut-Lien-Dut-Lien-Lien-Lien',
        note3: 'Thể',
        note6: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Hãy hoãn lại về sau này. Tính toán thật kỹ sau vài ngày nữa.<br><strong>SỰ NGHIỆP:</strong> Hãy kiên nhẫn khi phải đối đầu với trở ngại. Đừng vội vàng.<br><strong>TÀI LỘC:</strong> Mặc dù trông có vẻ tốt đẹp, tình hình rất khó khăn.<br><strong>KINH DOANH:</strong> Phải biết chờ đợi, không nên lao vào các công việc mới.<br><strong>TÌNH CẢM:</strong> Cầu được quẻ chờ đợi nghĩa là bạn phải chờ đợi, kiềm chế kích động.<br><strong>HỌC HÀNH:</strong> Thất bại là mẹ thành công. Hãy tiếp tục cố gắng tiến về phía trước.<br><strong>SỨC KHỎE: </strong>Chú ý phần đầu, não, phổi, hệ tiết niệu. Cần kiên trì chữa trị.'
    },
    {
        position: 6,
        tenque: 'THIÊN THỦY TỤNG',
        nguhanhgiadinhquai: 'HỎA',
        huyenkhongnguhanh: 9,
        huyenkhongquaivan: 3,
        thequai: 'DU HỒN QUÁI',
        hoagiap: 'TÂN MÙI',
        haothe: 4,
        haoung: 1,
        diachiquai1: 'Tử Tôn',
        diachiquai2: 'Tuất',
        diachiquai3: 'Thê Tài',
        diachiquai4: 'Thân',
        diachiquai5: 'Huynh Đệ',
        diachiquai6: 'Ngọ',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Ngọ',
        diachiquai9: 'Tử Tôn',
        diachiquai10: 'Thìn',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Dần',
        CodeQuai: 'Lien-Lien-Lien-Dut-Lien-Dut',
        note3: 'Thể',
        note6: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Không xuất hành trong lúc này, nếu cứ khăng khăng sẽ gặp rắc rối.<br><strong>SỰ NGHIỆP:</strong> Cẩn trọng với những người nham hiểm. Đề phòng tiểu nhân hại.<br><strong>TÀI LỘC:</strong> Tất cả mọi quyết định quan trọng cần phải hoãn lại.<br><strong>KINH DOANH:</strong> Dễ gây gỗ, cãi vã dẫn đến kiện tụng tốn kém. Cần bình tĩnh.<br><strong>TÌNH CẢM:</strong> Tranh cãi có thể xuất phát từ quan điểm khác nhau.<br><strong>HỌC HÀNH:</strong> Hãy đọc sách nhiều, không có cách nào rút ngắn học hành.<br><strong>SỨC KHỎE: </strong>Bệnh tình nặng ở phần eo, vai, hệ thống bài tiết, bệnh về phần đầu.<br><strong>NHÀ Ở:</strong> Cần chú ý kiểm tra đường ống nước, kẻo bị rò rỉ hao hụt.<br><strong>MỘ PHẦN:</strong> Xem chừng có nước ở khu vực phía Tây Bắc phản cung sát.'
    },
    {
        position: 7,
        tenque: 'ĐỊA THỦY SƯ',
        nguhanhgiadinhquai: 'THỦY',
        huyenkhongnguhanh: 1,
        huyenkhongquaivan: 7,
        thequai: 'QUY HỒN QUÁI',
        hoagiap: 'NHÂM THÂN',
        haothe: 3,
        haoung: 6,
        diachiquai1: 'Phụ Mẫu',
        diachiquai2: 'Dậu',
        diachiquai3: 'Huynh Đệ',
        diachiquai4: 'Hợi',
        diachiquai5: 'Quan Quỷ',
        diachiquai6: 'Sửu',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Ngọ',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Thìn',
        diachiquai11: 'Tử Tôn',
        diachiquai12: 'Dần',
        CodeQuai: 'Dut-Dut-Dut-Dut-Lien-Dut',
        note4: 'Thể',
        note1: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Hãy đi cùng tập thể, không nên đi một mình.<br><strong>SỰ NGHIỆP:</strong> Hãy nhớ rằng hoà khí sinh tài. Trái ngọt sẽ trổ sau khi hết khó khăn.<br><strong>TÀI LỘC:</strong> Nhiều cơ hội thu hút nhiều sự cạnh tranh. Bạn sẽ được tưởng thưởng.<br><strong>KINH DOANH:</strong> Phải đương đầu nhiều vấn đề rắc rối nhưng bạn sẽ thành công.<br><strong>TÌNH CẢM:</strong> Chú ý lời nói trong lúc cãi nhau, dễ làm tổn thương người bạn yêu.<br><strong>HỌC HÀNH:</strong> Gánh nặng công việc và sự cạnh tranh nghẹt thở làm bạn mệt mỏi.<br><strong>SỨC KHỎE: </strong>Bệnh cũ tái phát. Cẩn trọng sỏi thận và khối u dạ dày.<br><strong>MỘ PHẦN:</strong> Kiểm tra xem có bị úng nước hay không, sửa sang lại sẽ ổn hơn.<br><strong>MANG THAI:</strong> Sinh con trai tốt.<br><strong>MẤT ĐỒ:</strong> Khó tìm lại được.'
    },
    {
        position: 8,
        tenque: 'THỦY ĐỊA TỶ',
        nguhanhgiadinhquai: 'THỔ',
        huyenkhongnguhanh: 7,
        huyenkhongquaivan: 7,
        thequai: 'QUY HỒN QUÁI',
        hoagiap: 'TÂN HỢI',
        haothe: 3,
        haoung: 6,
        diachiquai1: 'Thê Tài',
        diachiquai2: 'Tý',
        diachiquai3: 'Huynh Đệ',
        diachiquai4: 'Tuất',
        diachiquai5: 'Tử Tôn',
        diachiquai6: 'Thân',
        diachiquai7: 'Quan Quỷ',
        diachiquai8: 'Mão',
        diachiquai9: 'Phụ Mẫu',
        diachiquai10: 'Tỵ',
        diachiquai11: 'Huynh Đệ',
        diachiquai12: 'Mùi',
        CodeQuai: 'Dut-Lien-Dut-Dut-Dut-Dut',
        note4: 'Thể',
        note1: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Đừng bỏ lỡ cơ hội, chớ nên từ chối, bạn sẽ có chuyến đi vui vẻ.<br><strong>SỰ NGHIỆP:</strong> Hãy trân trọng cơ hội tốt, bạn sẽ được quý nhân nâng đỡ.<br><strong>TÀI LỘC:</strong> Tránh làm việc một mình. Hãy làm việc theo đội nhóm.<br><strong>KINH DOANH:</strong> Hoà khí sinh tài, cố gắng hoà hợp và tạo sự vui vẻ, tiền bạc sẽ đến.<br><strong>TÌNH CẢM:</strong> Tình cảm tốt, có hạnh phúc và vui vẻ. Hôn nhân sẽ thuận lợi.<br><strong>HỌC HÀNH:</strong> Sự cạnh tranh lành mạnh với bạn học của bạn sẽ giúp tiến bộ.<br><strong>SỨC KHỎE: </strong>Cần chú ý phần bụng, vai, tai.<br><strong>MỘ PHẦN:</strong> Có đầm hồ xung quanh, gò nổi lên ở giữa.'
    },
    {
        position: 9,
        tenque: 'PHONG THIÊN TIỂU SÚC',
        nguhanhgiadinhquai: 'MỘC',
        huyenkhongnguhanh: 2,
        huyenkhongquaivan: 8,
        thequai: 'NHẤT THẾ QUÁI',
        hoagiap: 'ĐINH TỴ',
        haothe: 1,
        haoung: 4,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Mão',
        diachiquai3: 'Tử Tôn',
        diachiquai4: 'Tỵ',
        diachiquai5: 'Thê Tài',
        diachiquai6: 'Mùi',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Thìn',
        diachiquai9: 'Huynh Đệ',
        diachiquai10: 'Dần',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Tý',
        CodeQuai: 'Lien-Lien-Dut-Lien-Lien-Lien',
        note6: 'Thể',
        note3: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Giai đoạn này không lợi cho bạn dù xuất hành xa hay gần.<br><strong>SỰ NGHIỆP:</strong> Bạn đang có tiến bộ tốt nhưng đừng mất kiên nhẫn đòi hỏi kết quả.<br><strong>TÀI LỘC:</strong> Việc kinh doanh thịnh vượng nhưng chưa phải lúc để bùng nổ.<br><strong>KINH DOANH:</strong> Ở đây sự phát triển là việc kiếm từng đồng. Cần tiết kiệm.<br><strong>TÌNH CẢM:</strong> Bạn phải nhún nhường khi có khác biệt trong mối quan hệ tình cảm.<br><strong>HỌC HÀNH:</strong> Có nhiều khả năng tiến bộ hơn. Hãy kiên nhẫn, học hỏi mỗi ngày.<br><strong>SỨC KHỎE: </strong>Cẩn trọng các vấn đề sức khoẻ liên quan đến đầu, gan và túi mật.<br><strong>MỘ PHẦN:</strong> Mộ cụ bà thì tốt, cụ ông thì không tốt.<br><strong>KIỆN TỤNG:</strong> Có dây dưa, kéo dài nhưng kết cục là giải quyết được.'
    },
    {
        position: 10,
        tenque: 'THIÊN TRẠCH LÝ',
        nguhanhgiadinhquai: 'THỔ',
        huyenkhongnguhanh: 9,
        huyenkhongquaivan: 6,
        thequai: 'NGŨ THẾ QUÁI',
        hoagiap: 'MẬU THÌN',
        haothe: 5,
        haoung: 2,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Tuất',
        diachiquai3: 'Tử Tôn',
        diachiquai4: 'Thân',
        diachiquai5: 'Phụ Mẫu',
        diachiquai6: 'Ngọ',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Sửu',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Mão',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Tỵ',
        CodeQuai: 'Lien-Lien-Lien-Dut-Lien-Lien',
        note2: 'Thể',
        note5: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Hãy đi ngay và đừng do dự, chuyến đi này rất quan trọng.<br><strong>SỰ NGHIỆP:</strong> Tiến hành từng bước một thay vì mơ mộng thành công ngay lập tức.<br><strong>TÀI LỘC:</strong> Một tình huống có vẻ huận lợi vẫn hàm chứa nhiều bẫy bên trong.<br><strong>KINH DOANH:</strong> Đối tác kinh doanh của bạn là người đáng tin cậy.<br><strong>TÌNH CẢM:</strong> Nếu bạn chưa có người yêu, hãy đi xa du lịch và bạn sẽ tìm được.<br><strong>HỌC HÀNH:</strong> Đây là lúc bạn cần tập trung cho việc học hành. Đừng xao lãng.<br><strong>SỨC KHỎE: </strong>Cần chú ý bệnh tật nhỏ liên quan đến phổi, đầu, miệng và tứ chi.<br><strong>ĐỒ BỊ MẤT:</strong> Tìm bên dưới, dễ bị vật khác đè lên trên.<br><strong>THI CỬ:</strong> Dễ thi rớt, nếu thi lại, sẽ dễ đỗ hơn.'
    },
    {
        position: 11,
        tenque: 'ĐỊA THIÊN THÁI',
        nguhanhgiadinhquai: 'THỔ',
        huyenkhongnguhanh: 1,
        huyenkhongquaivan: 9,
        thequai: 'TAM THẾ QUÁI',
        hoagiap: 'CANH THÌN',
        haothe: 3,
        haoung: 6,
        diachiquai1: 'Tử Tôn',
        diachiquai2: 'Dậu',
        diachiquai3: 'Thê Tài',
        diachiquai4: 'Hợi',
        diachiquai5: 'Huynh Đệ',
        diachiquai6: 'Sửu',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Thìn',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Dần',
        diachiquai11: 'Thê Tài',
        diachiquai12: 'Tý',
        CodeQuai: 'Dut-Dut-Dut-Lien-Lien-Lien',
        note4: 'Thể',
        note1: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Xuất hành xa sẽ có lợi, bạn sẽ có nhiều mối quan hệ tốt.<br><strong>SỰ NGHIỆP:</strong> Mọi thứ diễn biến êm đẹp, đúng theo ý bạn muốn.<br><strong>TÀI LỘC:</strong> Bạn sẽ càng ngày càng phát tài, nhưng cần tỏ thái độ ôn hoà.<br><strong>KINH DOANH:</strong> Bạn đang vào thời kỳ gặt hái thành công, tuy nhiên đừng tự mãn.<br><strong>TÌNH CẢM:</strong> Tình yêu bền vững và chín muồi cho việc kết hôn.<br><strong>HỌC HÀNH:</strong> Dưới sự giúp đỡ của một người thầy tốt, bạn sẽ tiến bộ nhanh.<br><strong>SỨC KHỎE: </strong>Bệnh lâu dài bất lợi, chú ý bệnh về phần ruột, đầu.<br><strong>MỘ PHẦN:</strong> Yên ổn, ổn định.<br><strong>NHÀ CỬA:</strong> Nên tu sửa nhà cửa thì con cái hưởng tốt, tiền của thuận lợi.'
    },
    {
        position: 12,
        tenque: 'THIÊN ĐỊA BĨ',
        nguhanhgiadinhquai: 'KIM',
        huyenkhongnguhanh: 9,
        huyenkhongquaivan: 9,
        thequai: 'TAM THẾ QUÁI',
        hoagiap: 'CANH TUẤT',
        haothe: 3,
        haoung: 6,
        diachiquai1: 'Phụ Mẫu',
        diachiquai2: 'Tuất',
        diachiquai3: 'Huynh Đệ',
        diachiquai4: 'Thân',
        diachiquai5: 'Quan Quỷ',
        diachiquai6: 'Ngọ',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Mão',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Tỵ',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Mùi',
        CodeQuai: 'Lien-Lien-Lien-Dut-Dut-Dut',
        note4: 'Thể',
        note1: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Thời điểm không thích hợp, kiên trì chờ đợi là tốt nhất.<br><strong>SỰ NGHIỆP:</strong> Gây gỗ với đối tác, tiến độ công việc bị đình trệ, gặp bế tắc.<br><strong>TÀI LỘC:</strong> Không nên bỏ tiền đầu tư bất cứ một công việc gì, chỉ có thua lỗ.<br><strong>KINH DOANH:</strong> Gặp sự cạnh tranh, khó khăn chồng chất. Cần vững niềm tin.<br><strong>TÌNH CẢM:</strong> Tránh sử dụng những từ ngữ làm tổn thương đối phương.<br><strong>HỌC HÀNH:</strong> Tập trung kém sẽ khiến việc học bị sút kém.<br><strong>SỨC KHỎE: </strong>Đoán về bệnh là điềm hung, bệnh ở não, dạ dày, đề phòng ung thư.<br><strong>THI CỬ:</strong> Học hành kém, không làm được bài hoặc làm nhầm.<br><strong>SINH CON:</strong> Cần phải xem lại bếp, đầu giường nếu muốn có con.'
    },
    {
        position: 13,
        tenque: 'THIÊN HỎA ĐỒNG NHÂN',
        nguhanhgiadinhquai: 'HỎA',
        huyenkhongnguhanh: 9,
        huyenkhongquaivan: 7,
        thequai: 'QUY HỒN QUÁI',
        hoagiap: 'NHÂM DẦN',
        haothe: 3,
        haoung: 6,
        diachiquai1: 'Tử Tôn',
        diachiquai2: 'Tuất',
        diachiquai3: 'Thê Tài',
        diachiquai4: 'Thân',
        diachiquai5: 'Huynh Đệ',
        diachiquai6: 'Ngọ',
        diachiquai7: 'Quan Quỷ',
        diachiquai8: 'Hợi',
        diachiquai9: 'Tử Tôn',
        diachiquai10: 'Sửu',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Mão',
        CodeQuai: 'Lien-Lien-Lien-Lien-Dut-Lien',
        note4: 'Thể',
        note1: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Mọi việc đều suôn sẻ thuận lợi và bạn sẽ học được nhiều điều mới.<br><strong>SỰ NGHIỆP:</strong> Quan hệ tốt với đồng nghiệp, sếp sẽ giúp thăng tiến trong công việc.<br><strong>TÀI LỘC:</strong> Thời kỳ thuận lợi, tuy nhiên cần tiết kiệm cho quãng đường phía trước.<br><strong>KINH DOANH:</strong> Lợi tức tăng, khách hàng và đối tác tin tưởng, tương lai sáng sủa.<br><strong>TÌNH CẢM:</strong> Cần thông cảm, hiểu những điểm khác biệt sẽ giúp đi chung lâu dài.<br><strong>HỌC HÀNH:</strong> Bạn bè học chung sẽ giúp tiến bộ, nhưng tránh sa đà vui chơi.<br><strong>SỨC KHỎE: </strong>Chú ý sốt, viêm phổi và bệnh về mắt, táo bón, đặc biệt là tim.<br><strong>NHÀ CỬA:</strong> Nhà to, đẹp, sáng sủa, hỗ trợ nhân đinh địa vị cao.<br><strong>MỘ PHẦN:</strong> Yên ổn, tốt đẹp.'
    },
    {
        position: 14,
        tenque: 'HỎA THIÊN ĐẠI HỮU',
        nguhanhgiadinhquai: 'KIM',
        huyenkhongnguhanh: 3,
        huyenkhongquaivan: 7,
        thequai: 'QUY HỒN QUÁI',
        hoagiap: 'TÂN TỴ',
        haothe: 3,
        haoung: 6,
        diachiquai1: 'Quan Quỷ',
        diachiquai2: 'Tỵ',
        diachiquai3: 'Phụ Mẫu',
        diachiquai4: 'Mùi',
        diachiquai5: 'Huynh Đệ',
        diachiquai6: 'Dậu',
        diachiquai7: 'Phụ Mẫu',
        diachiquai8: 'Thìn',
        diachiquai9: 'Thê Tài',
        diachiquai10: 'Dần',
        diachiquai11: 'Tử Tôn',
        diachiquai12: 'Tý',
        CodeQuai: 'Lien-Dut-Lien-Lien-Lien-Lien',
        note4: 'Thể',
        note1: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Bạn sẽ có chuyến đi tuyệt vời nhưng hơi tốn kém.<br><strong>SỰ NGHIỆP:</strong> Mọi việc diễn tiến suôn sẻ và bạn đang ở đỉnh cao sự nghiệp.<br><strong>TÀI LỘC:</strong> Diễn tiến tích cực, nhưng vẫn cần phải quan sát tiểu tiết và cẩn trọng.<br><strong>KINH DOANH:</strong> Tiền vô như nước trong suốt thời kỳ thịnh vượng này.<br><strong>TÌNH CẢM:</strong> Tình cảm diễn biến theo chiều hướng thuận lợi như bạn ước ao.<br><strong>HỌC HÀNH:</strong> Khả năng học hỏi mạnh mẽ cho phép bạn đạt kết quả hứa hẹn.<br><strong>SỨC KHỎE: </strong>Cẩn trọng tránh bị sốt, huyết áp cao và bệnh về mắt.<br><strong>MỘ PHẦN:</strong> Mộ tổ phát phúc, con cháu hưởng phú quý.<br><strong>NHÀ CỬA:</strong> Nhà to đẹp, gia đạo bình an.'
    },
    {
        position: 15,
        tenque: 'ĐỊA SƠN KHIÊM',
        nguhanhgiadinhquai: 'KIM',
        huyenkhongnguhanh: 1,
        huyenkhongquaivan: 6,
        thequai: 'NGŨ THẾ QUÁI',
        hoagiap: 'MẬU TUẤT',
        haothe: 5,
        haoung: 2,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Dậu',
        diachiquai3: 'Tử Tôn',
        diachiquai4: 'Hợi',
        diachiquai5: 'Phụ Mẫu',
        diachiquai6: 'Sửu',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Thân',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Ngọ',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Thìn',
        CodeQuai: 'Dut-Dut-Dut-Lien-Dut-Dut',
        note2: 'Thể',
        note5: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Chuyến đi này có lơi nhất về mặt tinh thần cho bạn.<br><strong>SỰ NGHIỆP:</strong> Thái độ khiêm tốn sẽ giúp bạn leo cao hơn trong nấc thang sự nghiệp.<br><strong>TÀI LỘC:</strong> Nên gần gũi với người năng động cùng lý tưởng với bạn.<br><strong>KINH DOANH:</strong> Đừng cho mình có tài hơn họ. Càng hạ mình, càng có lợi hơn.<br><strong>TÌNH CẢM:</strong> Nên tôn trọng nhau để đảm bảo quan hệ tình cảm lâu dài.<br><strong>HỌC HÀNH:</strong> Tìm kiếm lời khuyên từ người thầy của bạn sẽ giúp bạn đột phá.<br><strong>SỨC KHỎE: </strong>Cẩn trọng bệnh tiêu hoá. Phụ nữ cũng cần chú ý đến bệnh phụ khoa.<br><strong>MỘ PHẦN:</strong> Có thể bị nứt, nên sửa lại mộ phần cho tốt, chú ý hướng Tây Nam.<br><strong>NHÀ CỬA:</strong> Người nhà biết kính trên nhường dưới, nên bố trí lại phong thuỷ nhà.'
    },
    {
        position: 16,
        tenque: 'LÔI ĐỊA DỰ',
        nguhanhgiadinhquai: 'MỘC',
        huyenkhongnguhanh: 8,
        huyenkhongquaivan: 8,
        thequai: 'NHẤT THẾ QUÁI',
        hoagiap: 'ĐINH HỢI',
        haothe: 1,
        haoung: 4,
        diachiquai1: 'Thê Tài',
        diachiquai2: 'Tuất',
        diachiquai3: 'Quan Quỷ',
        diachiquai4: 'Thân',
        diachiquai5: 'Tử Tôn',
        diachiquai6: 'Ngọ',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Mão',
        diachiquai9: 'Tử Tôn',
        diachiquai10: 'Tỵ',
        diachiquai11: 'Thê Tài',
        diachiquai12: 'Mùi',
        CodeQuai: 'Dut-Dut-Lien-Dut-Dut-Dut',
        note6: 'Thể',
        note3: 'Ứng',
        vieccanhoi:
            '<strong>NHÀ CỬA:</strong> Người nhà biết kính trên nhường dưới, nên bố trí lại phong thuỷ nhà.<br><strong>SỰ NGHIỆP:</strong> Đây là thời điểm thịnh vượng của bạn. Hãy nắm bắt mọi cơ hội.<br><strong>TÀI LỘC:</strong> Có thể bạn sẽ phát tài nên đầu tư làm ăn nếu như có cơ hội.<br><strong>KINH DOANH:</strong> Hãy làm những gì mà bạn thích, đam mê sẽ thúc đẩy kinh doanh.<br><strong>TÌNH CẢM:</strong> Dấu hiệu may mắn ám chỉ mối quan hệ hoà hợp.<br><strong>HỌC HÀNH:</strong> Đam mê học tập chăm chỉ sẽ giúp bạn đạt thành tích tột đỉnh.<br><strong>SỨC KHỎE: </strong>Tránh bệnh tật liên quan đến loét dạ dày, bao tử, gan và túi mật.<br><strong>MỘ PHẦN:</strong> Gần nơi có nhiều nước, tốt cho tài lộc con cháu.<br><strong>SINH CON:</strong> Sinh trai gái đầy đủ, trai thông minh, gái xinh đẹp.'
    },
    {
        position: 17,
        tenque: 'TRẠCH LÔI TÙY',
        nguhanhgiadinhquai: 'MỘC',
        huyenkhongnguhanh: 4,
        huyenkhongquaivan: 7,
        thequai: 'QUY HỒN QUÁI',
        hoagiap: 'ĐINH SỬU',
        haothe: 3,
        haoung: 6,
        diachiquai1: 'Thê Tài',
        diachiquai2: 'Mùi',
        diachiquai3: 'Quan Quỷ',
        diachiquai4: 'Dậu',
        diachiquai5: 'Phụ Mẫu',
        diachiquai6: 'Hợi',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Thìn',
        diachiquai9: 'Huynh Đệ',
        diachiquai10: 'Dần',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Tý',
        CodeQuai: 'Dut-Lien-Lien-Dut-Dut-Lien',
        note4: 'Thể',
        note1: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Hãy đi đến nơi nào bạn thích, bạn sẽ tìm lại được hứng khởi.<br><strong>SỰ NGHIỆP:</strong> Cần phải thích nghi với hoàn cảnh, tránh cứng nhắc, dễ gặp khó.<br><strong>TÀI LỘC:</strong> Hãy giới thiệu sản phẩm mới, phù hợp với nhu cầu của khách hàng.<br><strong>KINH DOANH:</strong> Chỉ cần lắng nghe thị trường, bạn sẽ biết cách kiếm nhiều tiền.<br><strong>TÌNH CẢM:</strong> Đừng cố gắng chinh phục mục tiêu, hãy để cho tình cảm thật tự nhiên.<br><strong>HỌC HÀNH:</strong> Tuân theo quy luật và học thật chăm. Nỗ lực cố gắng rất cần thiết.<br><strong>SỨC KHỎE: </strong>Cẩn trọng tránh tai nạn. Không nên lái xe khi đã uống rượu bia.'
    },
    {
        position: 18,
        tenque: 'SƠN PHONG CỔ',
        nguhanhgiadinhquai: 'MỘC',
        huyenkhongnguhanh: 6,
        huyenkhongquaivan: 7,
        thequai: 'QUY HỒN QUÁI',
        hoagiap: 'ĐINH MÙI',
        haothe: 3,
        haoung: 6,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Dần',
        diachiquai3: 'Phụ Mẫu',
        diachiquai4: 'Tý',
        diachiquai5: 'Thê Tài',
        diachiquai6: 'Tuất',
        diachiquai7: 'Quan Quỷ',
        diachiquai8: 'Dậu',
        diachiquai9: 'Phụ Mẫu',
        diachiquai10: 'Hợi',
        diachiquai11: 'Thê Tài',
        diachiquai12: 'Sửu',
        CodeQuai: 'Lien-Dut-Dut-Lien-Lien-Dut',
        note4: 'Thể',
        note1: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Tốt nhất nên ở nhà, nếu như bắt buộc, phải sắp xếp kỹ lưỡng.<br><strong>SỰ NGHIỆP:</strong> Mặc dù bề ngoài có vẻ ổn định, địa vị của bạn đang nhạy cảm.<br><strong>TÀI LỘC:</strong> Không nên đứng yên như hiện giờ nếu không bạn sẽ chuốc họa vào thân.<br><strong>KINH DOANH:</strong> Không nên quá cứng nhắc trong nhận xét người khác.<br><strong>TÌNH CẢM:</strong> Dễ có tình cảm giữa chị và em trai, hoặc với người nam trẻ tuổi.<br><strong>SỨC KHỎE: </strong>Nên tận dụng thời cơ để trị bệnh. Cẩn trọng bệnh ở nội tạng.<br><strong>MỘ PHẦN:</strong> Xấu, cần kiểm tra lại nếu không sẽ ảnh hưởng nặng con cháu. Nhất là những mộ tổ lâu đời dễ bị hư hại.<br><strong>HỌC HÀNH:</strong> Đừng chủ quan, nên ôn lại những kiến thức căn bản mà bạn mất gốc<br><strong>NHÀ CỬA:</strong> Nhà ở trên đồi, hoặc cao nhất thường bị phong sát, cần được che chắn.'
    },
    {
        position: 19,
        tenque: 'ĐỊA TRẠCH LÂM',
        nguhanhgiadinhquai: 'THỔ',
        huyenkhongnguhanh: 1,
        huyenkhongquaivan: 4,
        thequai: 'NHỊ THẾ QUÁI',
        hoagiap: 'ẤT MÃO',
        haothe: 2,
        haoung: 5,
        diachiquai1: 'Tử Tôn',
        diachiquai2: 'Dậu',
        diachiquai3: 'Thê Tài',
        diachiquai4: 'Hợi',
        diachiquai5: 'Huynh Đệ',
        diachiquai6: 'Sửu',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Sửu',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Mão',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Tỵ',
        CodeQuai: 'Dut-Dut-Dut-Dut-Lien-Lien',
        note5: 'Thể',
        note2: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Có lợi , trong chuyến đi bạn sẽ học hỏi được kinh nghiệm quí giá.<br><strong>SỰ NGHIỆP:</strong> Niềm tin là nhân tố rất quan trọng trong công việc. Nên lấy lòng người.<br><strong>TÀI LỘC:</strong> Góc nhìn đa chiều sẽ đem lại thành công mới. Cần tôn trọng sự góp ý.<br><strong>KINH DOANH:</strong> Tránh lạm dụng quyền hành khi không cần thiết, ngay cả khi bạn đang có lợi thế.<br><strong>TÌNH CẢM:</strong> Cứ chân thành, tình cảm đang diễn biến tốt đẹp, có lợi cho bạn.<br><strong>HỌC HÀNH:</strong> Hãy nhìn xa và tiến hành những bước đi thực tế để đạt mục tiêu.<br><strong>SỨC KHỎE: </strong>Bệnh nhẹ, về phần bao tử, miệng. Hãy chú ý tránh vấn đề tiêu hoá.'
    },
    {
        position: 20,
        tenque: 'PHONG ĐỊA QUAN',
        nguhanhgiadinhquai: 'KIM',
        huyenkhongnguhanh: 2,
        huyenkhongquaivan: 2,
        thequai: 'TỨ THẾ QUÁI',
        hoagiap: 'KỶ HỢI',
        haothe: 4,
        haoung: 1,
        diachiquai1: 'Thê Tài',
        diachiquai2: 'Mão',
        diachiquai3: 'Quan Quỷ',
        diachiquai4: 'Tỵ',
        diachiquai5: 'Phụ Mẫu',
        diachiquai6: 'Mùi',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Mão',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Tỵ',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Mùi',
        CodeQuai: 'Lien-Lien-Dut-Dut-Dut-Dut',
        note3: 'Thể',
        note6: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Có lợi, đi nhanh nhất là đường hàng không sẽ có lợi.<br><strong>SỰ NGHIỆP:</strong> Cần quan sát cẩn trọng trước khi quyết định việc trọng đại.<br><strong>TÀI LỘC:</strong> Cần chờ đợi hướng thị trường rõ ràng trước khi quyết định mua bán.<br><strong>KINH DOANH:</strong> Cần chú ý thắng lợi cho cả hai bên nếu muốn hợp tác lâu dài.<br><strong>TÌNH CẢM:</strong> Mối quan hệ không bền vững. Hãy cố gắng hiểu cảm xúc người khác.<br><strong>HỌC HÀNH:</strong> Tập trung kém sẽ ảnh hưởng đến việc học. Hãy tham gia một nhóm học tập sẽ giúp bạn học tốt hơn.<br><strong>SỨC KHỎE: </strong>Biến chứng, ác tính, thay đổi khá nhiều, bệnh về bao tử, hệ thần kinh.<br><strong>HÔN NHÂN:</strong> Khó được như ý, nên cân nhắc để tránh ly hôn.<br><strong>NHÀ Ở:</strong> Kiểm tra góc Đông Nam và Tây Nam, dễ bị sát khí.'
    },
    {
        position: 21,
        tenque: 'HỎA LÔI PHỆ HẠP',
        nguhanhgiadinhquai: 'MỘC',
        huyenkhongnguhanh: 3,
        huyenkhongquaivan: 6,
        thequai: 'NGŨ THẾ QUÁI',
        hoagiap: 'ẤT SỬU',
        haothe: 5,
        haoung: 2,
        diachiquai1: 'Tử Tôn',
        diachiquai2: 'Tỵ',
        diachiquai3: 'Thê Tài',
        diachiquai4: 'Mùi',
        diachiquai5: 'Quan Quỷ',
        diachiquai6: 'Dậu',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Thìn',
        diachiquai9: 'Huynh Đệ',
        diachiquai10: 'Dần',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Tý',
        CodeQuai: 'Lien-Dut-Lien-Dut-Dut-Lien',
        note2: 'Thể',
        note5: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Cần rà soát, kiểm tra đối tác, tránh người không trung thực.<br><strong>SỰ NGHIỆP:</strong> Đầu óc tư tưởng thoải mái sẽ là chìa khoá vượt mọi khó khăn.<br><strong>TÀI LỘC:</strong> Hãy cố gắng giữ tình trạng hiện tại trong thời điểm khó khăn này.<br><strong>KINH DOANH:</strong> Đừng ngại từ chối hợp tác, bạn có thể bị phản bội.<br><strong>TÌNH CẢM:</strong> Kẻ thứ ba hay tình yêu tay ba sẽ xuất hiện. Hãy cố gắng, sẽ cứu vãn được tình yêu. Không dành đủ thời gian cho người kia, bạn sẽ hối tiếc.<br><strong>HỌC HÀNH:</strong> Hãy làm việc thật chăm chỉ để cải thiện kết quả sút kém.<br><strong>SỨC KHỎE: </strong>Cẩn trọng tránh u xơ, ung thư vú và bệnh tiêu hoá.<br><strong>SINH CON:</strong> Trai gái đầy đủ và thông minh.'
    },
    {
        position: 22,
        tenque: 'SƠN HỎA BÍ',
        nguhanhgiadinhquai: 'THỔ',
        huyenkhongnguhanh: 6,
        huyenkhongquaivan: 8,
        thequai: 'NHẤT THẾ QUÁI',
        hoagiap: 'QUÝ SỬU',
        haothe: 1,
        haoung: 4,
        diachiquai1: 'Quan Quỷ',
        diachiquai2: 'Dần',
        diachiquai3: 'Thê Tài',
        diachiquai4: 'Tý',
        diachiquai5: 'Huynh Đệ',
        diachiquai6: 'Tuất',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Hợi',
        diachiquai9: 'Huynh Đệ',
        diachiquai10: 'Sửu',
        diachiquai11: 'Quan Quỷ',
        diachiquai12: 'Mão',
        CodeQuai: 'Lien-Dut-Dut-Lien-Dut-Lien',
        note6: 'Thể',
        note3: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Bạn hãy khởi hành ngay, bạn sẽ vui vẻ trong kỳ nghỉ này.<br><strong>SỰ NGHIỆP:</strong> Thay vì chém gió, khoe khoang, hãy tỏ ra khiêm tốn.<br><strong>TÀI LỘC:</strong> Tình huống có vẻ thuận lợi nhưng có thể không phải như vậy.<br><strong>KINH DOANH:</strong> Hãy tỉnh táo nhìn nhận sự việc thật sự, không bị vẻ ngoài của nó cuốn hút mà quên đánh giá đúng thực chất.<br><strong>TÌNH CẢM:</strong> Vẻ ngoài xinh đẹp cuốn hút ban đầu, hãy tìm hiểu tâm hồn bên trong.<br><strong>HỌC HÀNH:</strong> Ngoài việc đạt thành tích đáng thoả mãn, bạn không được cho phép bản thân tự mãn. Hãy tiếp tục cố gắng!<br><strong>SỨC KHỎE: </strong>Bệnh nặng, chú ý phần tim, não và xét kỹ các u. Tình hình sức khoẻ đang tốt lên nhưng bạn không được chủ quan.<br><strong>MẤT CỦA:</strong> Cần kiểm tra chi phí cẩn trọng, nếu không dễ bị túng thiếu về sau.'
    },
    {
        position: 23,
        tenque: 'SƠN ĐỊA BÁC',
        nguhanhgiadinhquai: 'KIM',
        huyenkhongnguhanh: 6,
        huyenkhongquaivan: 6,
        thequai: 'NGŨ THẾ QUÁI',
        hoagiap: 'QUÝ HỢI',
        haothe: 5,
        haoung: 2,
        diachiquai1: 'Thê Tài',
        diachiquai2: 'Dần',
        diachiquai3: 'Tử Tôn',
        diachiquai4: 'Tý',
        diachiquai5: 'Phụ Mẫu',
        diachiquai6: 'Tuất',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Mão',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Tỵ',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Mùi',
        CodeQuai: 'Lien-Dut-Dut-Dut-Dut-Dut',
        note2: 'Thể',
        note5: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Tránh tuyệt đối, không phải là thời điểm để bạn xuất hành.<br><strong>SỰ NGHIỆP:</strong> Tình huống thích hợp để nghỉ ngơi hơn là làm việc. Đừng tiếp tục.<br><strong>TÀI LỘC:</strong> Chưa gặp may mắn, tiền bạc còn khó khăn nên xài tiền cẩn thận.<br><strong>KINH DOANH:</strong> Tình thế thật sự nguy hiểm. Không thuận lợi, dễ bị mất mát.<br><strong>TÌNH CẢM:</strong> Sự gãy đổ, kết thúc cuộc tình, sự chia tay. Sự hiểu lầm, khó hàn gắn.<br><strong>HỌC HÀNH:</strong> Hãy tập trung vào việc học hành trước tiên, tránh xa yêu đương.<br><strong>SỨC KHỎE: </strong>Bệnh tình hung ác, đề phòng đau đầu, bao tử và thận.<br><strong>NHÀ Ở:</strong> Nhà cũ nát, nghèo.'
    },
    {
        position: 24,
        tenque: 'ĐỊA LÔI PHỤC',
        nguhanhgiadinhquai: 'THỔ',
        huyenkhongnguhanh: 1,
        huyenkhongquaivan: 8,
        thequai: 'NHẤT THẾ QUÁI',
        hoagiap: 'GIÁP TÝ',
        haothe: 1,
        haoung: 4,
        diachiquai1: 'Tử Tôn',
        diachiquai2: 'Dậu',
        diachiquai3: 'Thê Tài',
        diachiquai4: 'Hợi',
        diachiquai5: 'Huynh Đệ',
        diachiquai6: 'Sửu',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Thìn',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Dần',
        diachiquai11: 'Thê Tài',
        diachiquai12: 'Tý',
        CodeQuai: 'Dut-Dut-Dut-Dut-Dut-Lien',
        note6: 'Thể',
        note3: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Tâm bất ổn, cần xem xét kỹ trước khi đi.<br><strong>SỰ NGHIỆP:</strong> Cơ hội sẽ quay trở lại, gây dựng lại từ đầu.<br><strong>TÀI LỘC:</strong> Có khả năng thu nhập tốt.<br><strong>KINH DOANH:</strong> Có thể thành công, cần kiên trì và nhẫn nại.<br><strong>TÌNH CẢM:</strong> Gấp gáp sẽ thất bại, tự nhiên và tiến chậm sẽ thành công.<br><strong>HỌC HÀNH:</strong> Nên ôn lại những gì căn bản. Thi lại sẽ tốt hơn lần đầu.<br><strong>SỨC KHỎE: </strong>Kiểm tra vùng gan, đùi, chân và hệ tiêu hóa.<br><strong>NHÀ CỬA:</strong> Bất hòa giữa mẹ và con trai.<br><strong>SINH CON:</strong> Khó sinh con, dễ sinh gái.<br><strong>MẤT CỦA:</strong> Có khả năng tìm lại.'
    },
    {
        position: 25,
        tenque: 'THIÊN LÔI VÔ VỌNG',
        nguhanhgiadinhquai: 'MỘC',
        huyenkhongnguhanh: 9,
        huyenkhongquaivan: 2,
        thequai: 'TỨ THẾ QUÁI',
        hoagiap: 'KỶ SỬU',
        haothe: 4,
        haoung: 1,
        diachiquai1: 'Thê Tài',
        diachiquai2: 'Tuất',
        diachiquai3: 'Quan Quỷ',
        diachiquai4: 'Thân',
        diachiquai5: 'Tử Tôn',
        diachiquai6: 'Ngọ',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Thìn',
        diachiquai9: 'Huynh Đệ',
        diachiquai10: 'Dần',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Tý',
        CodeQuai: 'Lien-Lien-Lien-Dut-Dut-Lien',
        note3: 'Thể',
        note6: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Tốt hơn là không nên xuất hành trong lúc này<br><strong>SỰ NGHIỆP:</strong> Đừng quá cứng nhắc. Hãy xem xét những mức giới hạn của bạn.<br><strong>TÀI LỘC:</strong> Mặc dù thị trường bất ổn, có trật tự giữa sự hỗn loạn. Hãy bình tĩnh.<br><strong>KINH DOANH:</strong> Thận trọng phải là kim chỉ nam của bạn. Đừng quá so đo.<br><strong>TÌNH CẢM:</strong> Cảm xúc bất ổn sẽ tạo ra những mâu thuẫn không ngừng.<br><strong>HỌC HÀNH:</strong> Cha mẹ cần hỗ trợ và hướng dẫn cho con cái.<br><strong>SỨC KHỎE: </strong>Bệnh tật lây nhiễm có liên quan đến hệ hô hấp, đầu và chân.<br><strong>NHÀ CỬA:</strong> Nhà to đẹp, người trong nhà xem trọng hư danh.<br><strong>SINH CON:</strong> Sinh trai nhiều hơn gái.<br><strong>MỘ PHẦN:</strong> Có vấn đề, nên cho kiểm tra lại phần mộ.'
    },
    {
        position: 26,
        tenque: 'SƠN THIÊN ĐẠI SÚC',
        nguhanhgiadinhquai: 'THỔ',
        huyenkhongnguhanh: 6,
        huyenkhongquaivan: 4,
        thequai: 'NHỊ THẾ QUÁI',
        hoagiap: 'NHÂM THÌN',
        haothe: 2,
        haoung: 5,
        diachiquai1: 'Quan Quỷ',
        diachiquai2: 'Dần',
        diachiquai3: 'Thê Tài',
        diachiquai4: 'Tý',
        diachiquai5: 'Huynh Đệ',
        diachiquai6: 'Tuất',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Thìn',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Dần',
        diachiquai11: 'Thê Tài',
        diachiquai12: 'Tý',
        CodeQuai: 'Lien-Dut-Dut-Lien-Lien-Lien',
        note5: 'Thể',
        note2: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Rất thuận lợi là nguồn gốc của sự thăng chức.<br><strong>SỰ NGHIỆP:</strong> Hãy khiêm tốn mặc dù bạn có tài cao.<br><strong>TÀI LỘC:</strong> Tình hình sẽ chuyển biến có lợi cho bạn nhưng bạn phải cẩn trọng.<br><strong>KINH DOANH:</strong> Bạn sẽ đi đến thành công. Ảnh hưởng của bạn rất mạnh trong xã hội, sẽ đạt địa vị xã hội cao.<br><strong>TÌNH CẢM:</strong> Cần kiên nhẫn, người yêu của bạn rốt cuộc sẽ lắng nghe và chiều ý.<br><strong>HỌC HÀNH:</strong> Thành tích cải thiện sẽ đem cho bạn sự tự tin cao hơn.<br><strong>SỨC KHỎE: </strong>Bệnh nặng, nhưng chữa được, đề phòng bệnh phần tim, tay, nếu không tích cưc, để lâu biến chứng nặng hơn.<br><strong>MẤT CỦA:</strong> Bị vât khác đè lên.'
    },
    {
        position: 27,
        tenque: 'SƠN LÔI DI',
        nguhanhgiadinhquai: 'MỘC',
        huyenkhongnguhanh: 6,
        huyenkhongquaivan: 3,
        thequai: 'DU HỒN QUÁI',
        hoagiap: 'BÍNH TÝ',
        haothe: 4,
        haoung: 1,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Dần',
        diachiquai3: 'Phụ Mẫu',
        diachiquai4: 'Tý',
        diachiquai5: 'Thê Tài',
        diachiquai6: 'Tuất',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Thìn',
        diachiquai9: 'Huynh Đệ',
        diachiquai10: 'Dần',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Tý',
        CodeQuai: 'Lien-Dut-Dut-Dut-Dut-Lien',
        note3: 'Thể',
        note6: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Trong lúc này nên ở lại nhà, không được đi đâu cả.<br><strong>SỰ NGHIỆP:</strong> Thời kỳ ổn định cho phép bạn tiến bộ nhiều.<br><strong>TÀI LỘC:</strong> Chưa có gì sáng sủa.<br><strong>KINH DOANH:</strong> Hãy thỏa mãn với những gì mình có.<br><strong>TÌNH CẢM:</strong> Hãy chú ý quan tâm đến họ nhiều hơn.<br><strong>HỌC HÀNH:</strong> Hãy tập trung học hành thật chăm chỉ.<br><strong>SỨC KHỎE: </strong>Bệnh về bao tử, cổ họng, chân tay. Hãy chú ý chế độ ăn uống.'
    },
    {
        position: 28,
        tenque: 'TRẠCH PHONG ĐẠI QUÁ',
        nguhanhgiadinhquai: 'MỘC',
        huyenkhongnguhanh: 4,
        huyenkhongquaivan: 3,
        thequai: 'DU HỒN QUÁI',
        hoagiap: 'BÍNH NGỌ',
        haothe: 4,
        haoung: 1,
        diachiquai1: 'Thê Tài',
        diachiquai2: 'Mùi',
        diachiquai3: 'Quan Quỷ',
        diachiquai4: 'Dậu',
        diachiquai5: 'Phụ Mẫu',
        diachiquai6: 'Hợi',
        diachiquai7: 'Quan Quỷ',
        diachiquai8: 'Dậu',
        diachiquai9: 'Phụ Mẫu',
        diachiquai10: 'Hợi',
        diachiquai11: 'Thê Tài',
        diachiquai12: 'Sửu',
        CodeQuai: 'Dut-Lien-Lien-Lien-Lien-Dut',
        note3: 'Thể',
        note6: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Dĩ nhiên bạn có thể xuất hành nhưng đừng đi quá xa.<br><strong>SỰ NGHIỆP:</strong> Mặc dù có cơ hội tốt, bạn nên làm việc nỗ lực trong khả năng của mình. Đừng ép buộc bản thân nếu bạn không thể tiếp nhận thêm trách nhiệm.<br><strong>TÀI LỘC:</strong> Phải chi tiêu cẩn thận vì chưa gặp được sự may mắn.<br><strong>KINH DOANH:</strong> Đừng quá tham lam. Hãy chọn con đường chính đáng nếu không sẽ gặp tai ương.<br><strong>TÌNH CẢM:</strong> Đừng tạo áp lực quá lớn cho bạn đời của mình. Tôn trọng lẫn nhau là chìa khoá cho quan hệ lâu dài.<br><strong>HỌC HÀNH:</strong> Những mục tiêu phi thực tế sẽ khiến bạn bị choáng ngợp, căng thẳng và ảnh hưởng đến thành tích.<br><strong>SỨC KHỎE: </strong>Nghiêm trọng, nên nhẫn nại chữa trị, bệnh về thần kinh.'
    },
    {
        position: 29,
        tenque: 'KHẢM VI THỦY',
        nguhanhgiadinhquai: 'THỦY',
        huyenkhongnguhanh: 7,
        huyenkhongquaivan: 1,
        thequai: 'BÁT THUẦN QUÁI',
        hoagiap: 'CANH THÂN',
        haothe: 6,
        haoung: 3,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Tý',
        diachiquai3: 'Quan Quỷ',
        diachiquai4: 'Tuất',
        diachiquai5: 'Phụ Mẫu',
        diachiquai6: 'Thân',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Ngọ',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Thìn',
        diachiquai11: 'Tử Tôn',
        diachiquai12: 'Dần',
        CodeQuai: 'Dut-Lien-Dut-Dut-Lien-Dut',
        note1: 'Thể',
        note4: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Bỏ tất cả ngay cả những buổi đi nghỉ cuối tuần.<br><strong>SỰ NGHIỆP:</strong> Hãy xử lý mọi vấn đề một cách thận trọng. Suy nghĩ tích cực!<br><strong>TÀI LỘC:</strong> Ngừng đầu tư trong khi thị trường đang hỗn loạn.<br><strong>KINH DOANH:</strong> Hãy thận trọng và suy nghĩ chính chắn, sự may măn không mỉm cười với bạn. Tránh các việc kinh doanh mới.<br><strong>TÌNH CẢM:</strong> Đừng từ bỏ một cách dễ dàng.<br><strong>HỌC HÀNH:</strong> Những trở ngại thường xuyên sẽ khiến cho bạn nản chí và học hành kém.<br><strong>SỨC KHỎE: </strong>Bệnh nặng cần nhanh chóng chữa trị, nếu không khó cứu, chú ý vai, bàng quang, sinh bệnh nặng. Cần đi khám ngay nếu bạn cảm thấy không khoẻ.'
    },
    {
        position: 30,
        tenque: 'LY VI HỎA',
        nguhanhgiadinhquai: 'HỎA',
        huyenkhongnguhanh: 3,
        huyenkhongquaivan: 1,
        thequai: 'BÁT THUẦN QUÁI',
        hoagiap: 'CANH DẦN',
        haothe: 6,
        haoung: 3,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Tỵ',
        diachiquai3: 'Tử Tôn',
        diachiquai4: 'Mùi',
        diachiquai5: 'Thê Tài',
        diachiquai6: 'Dậu',
        diachiquai7: 'Quan Quỷ',
        diachiquai8: 'Hợi',
        diachiquai9: 'Tử Tôn',
        diachiquai10: 'Sửu',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Mão',
        CodeQuai: 'Lien-Dut-Lien-Lien-Dut-Lien',
        note1: 'Thể',
        note4: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Rất vui trong lúc này.<br><strong>SỰ NGHIỆP:</strong> Tiến bước mạnh mẽ sẽ giúp đạt kết quả nhưng bạn vẫn phải tôn trọng người khác.<br><strong>TÀI LỘC:</strong> Vẻ ngoài thuận lợi báo hiệu khả năng thành công. Có thể phát đạt nhưng tránh lạm dụng.<br><strong>KINH DOANH:</strong> Điều cần làm thiết yếu là bạn hãy làm những gì bạn thích.<br><strong>TÌNH CẢM:</strong> Bạn có khởi đầu tốt trong tình cảm nhưng hãy cố gắng để cho tình cảm diễn biến chậm hơn và bền hơn.<br><strong>HỌC HÀNH:</strong> Hãy dành thời gian tập thể dục để đốt bớt năng lượng dư thừa.<br><strong>SỨC KHỎE: </strong>Bệnh nặng có thể chữa được, cần chú tâm điều dưỡng, nếu để lâu có thể nặng. Bệnh về tim, mắt, máu huyết có thể xuất hiện bất ngờ. Hãy cẩn trọng.'
    },
    {
        position: 31,
        tenque: 'TRẠCH SƠN HÀM',
        nguhanhgiadinhquai: 'KIM',
        huyenkhongnguhanh: 4,
        huyenkhongquaivan: 9,
        thequai: 'TAM THẾ QUÁI',
        hoagiap: 'ĐINH DẬU',
        haothe: 3,
        haoung: 6,
        diachiquai1: 'Phụ Mẫu',
        diachiquai2: 'Mùi',
        diachiquai3: 'Huynh Đệ',
        diachiquai4: 'Dậu',
        diachiquai5: 'Tử Tôn',
        diachiquai6: 'Hợi',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Thân',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Ngọ',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Thìn',
        CodeQuai: 'Dut-Lien-Lien-Lien-Dut-Dut',
        note4: 'Thể',
        note1: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Sẽ thuận lợi, rất có thể bạn sẽ gặp tình yêu trong chuyến đi.<br><strong>SỰ NGHIỆP:</strong> Mọi việc sẽ diễn biến thuận lợi với sự trợ giúp của quý nhân.<br><strong>TÀI LỘC:</strong> Tất cả mọi điều bạn thiếu là gặp đúng người.<br><strong>KINH DOANH:</strong> Các hoạt động phải có lợi cho người khác, buồn phiền sẽ đến nếu bạn tỏ ra ích kỷ.<br><strong>TÌNH CẢM:</strong> Tình cảm từ 2 phía. Để làm cho mối quan hệ tốt đẹp, hãy đối xử với nhau chân thành và chung thuỷ.<br><strong>HỌC HÀNH:</strong> Hãy cẩn trọng đừng để chuyện yêu đương ảnh hưởng việc học.<br><strong>SỨC KHỎE: </strong>Chú ý bệnh truyền nhiễm. Ngoài ra, cần chú ý hệ hô hấp và tiêu hoá.'
    },
    {
        position: 32,
        tenque: 'LÔI PHONG HẰNG',
        nguhanhgiadinhquai: 'MỘC',
        huyenkhongnguhanh: 8,
        huyenkhongquaivan: 9,
        thequai: 'TAM THẾ QUÁI',
        hoagiap: 'CANH NGỌ',
        haothe: 3,
        haoung: 6,
        diachiquai1: 'Thê Tài',
        diachiquai2: 'Tuất',
        diachiquai3: 'Quan Quỷ',
        diachiquai4: 'Thân',
        diachiquai5: 'Tử Tôn',
        diachiquai6: 'Ngọ',
        diachiquai7: 'Quan Quỷ',
        diachiquai8: 'Dậu',
        diachiquai9: 'Phụ Mẫu',
        diachiquai10: 'Hợi',
        diachiquai11: 'Thê Tài',
        diachiquai12: 'Sửu',
        CodeQuai: 'Dut-Dut-Lien-Lien-Lien-Dut',
        note4: 'Thể',
        note1: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Không có dấu hiệu phải thay đổi. du lịch, dọn nhà sợ sẽ làm bạn xa rời mục tiêu, tốt hơn nên từ bỏ.<br><strong>SỰ NGHIỆP:</strong> Ngoài may mắn thuận lợi, bạn phải nỗ lực chăm chỉ và kiên trì. Đừng quá sốt ruột đòi hỏi kết quả, nếu không bạn dễ thất bại.<br><strong>TÀI LỘC:</strong> Sẽ gặp được may mắn.<br><strong>KINH DOANH:</strong> Phải thực hiện công việc một cách thường xuyên và phải khôn ngoan thu nhập kinh nghiệm của những người đi trước.<br><strong>TÌNH CẢM:</strong> Sự cân bằng hoà hợp giữa tính mạnh mẽ và dịu dàng hoàn hảo.<br><strong>HỌC HÀNH:</strong> Thực hành tạo nên sự xuất sắc.<br><strong>SỨC KHỎE: </strong>Cần chú ý đến phần bệnh tật liên quan đến chi, gan và túi mật.<br><strong>TRANH CHẤP KIỆN TỤNG:</strong> Dễ đi đến thống nhất hoà giải.<br><strong>MẤT CỦA:</strong> Khó tìm thấy.'
    },
    {
        position: 33,
        tenque: 'THIÊN SƠN ĐỘN',
        nguhanhgiadinhquai: 'KIM',
        huyenkhongnguhanh: 9,
        huyenkhongquaivan: 4,
        thequai: 'NHỊ THẾ QUÁI',
        hoagiap: 'ẤT DẬU',
        haothe: 2,
        haoung: 5,
        diachiquai1: 'Phụ Mẫu',
        diachiquai2: 'Tuất',
        diachiquai3: 'Huynh Đệ',
        diachiquai4: 'Thân',
        diachiquai5: 'Quan Quỷ',
        diachiquai6: 'Ngọ',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Thân',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Ngọ',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Thìn',
        CodeQuai: 'Lien-Lien-Lien-Lien-Dut-Dut',
        note5: 'Thể',
        note2: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Đây là thời điểm ở nhà không nên đi nghỉ ở đâu cả.<br><strong>SỰ NGHIỆP:</strong> Đi chậm. Có nhiều trở ngại. Đừng bám víu vào những mục tiêu không thực tế và hành động hấp tấp.<br><strong>TÀI LỘC:</strong> Tránh đầu cơ những lĩnh vực không quen thuộc.<br><strong>KINH DOANH:</strong> Không nên nghĩ đến việc kinh doanh vào thời điểm này.<br><strong>TÌNH CẢM:</strong> Nếu không muốn chia tay với người mình yêu mến, không còn giải pháp nào tốt hơn là qui thuận theo các yêu cầu của người đó.<br><strong>HỌC HÀNH:</strong> Vận học hành. Đây là lúc để nỗ lực hơn.<br><strong>SỨC KHỎE: </strong>Bệnh nặng, chú ý máu huyết, đầu, lưng, gân, xương.'
    },
    {
        position: 34,
        tenque: 'LÔI THIÊN ĐẠI TRÁNG',
        nguhanhgiadinhquai: 'THỔ',
        huyenkhongnguhanh: 8,
        huyenkhongquaivan: 2,
        thequai: 'TÚ THẾ QUÁI',
        hoagiap: 'KỶ TỴ',
        haothe: 4,
        haoung: 1,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Tuất',
        diachiquai3: 'Tử Tôn',
        diachiquai4: 'Thân',
        diachiquai5: 'Phụ Mẫu',
        diachiquai6: 'Ngọ',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Thìn',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Dần',
        diachiquai11: 'Thê Tài',
        diachiquai12: 'Tý',
        CodeQuai: 'Dut-Dut-Lien-Lien-Lien-Lien',
        note3: 'Thể',
        note6: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Bạn đang có cơ hội, nên bắt lấy dịp may<br><strong>SỰ NGHIỆP:</strong> Giữa lúc đang phát triển nhanh chóng, bạn phải khiêm tốn và tôn trọng tất cả mọi người.<br><strong>TÀI LỘC:</strong> Sẽ gặp may mắn, sự ước mong sẽ thành đạt. Dấu hiệu mối quan hệ tốt bị thất bại vào phút chót, khiến cho mọi nỗ lực bị phá huỷ.<br><strong>KINH DOANH:</strong> Có sự thăng tiếng, đừng quá lợi dụng ảnh hưởng của bạn.<br><strong>TÌNH CẢM:</strong> Hãy luôn ghi nhớ và trân trọng bạn đời của bạn.<br><strong>HỌC HÀNH:</strong> Thiếu kiên tâm có thể làm cho kết quả học hành thi cử của bạn trồi sụt. Hãy tập trung và kiên nhẫn.<br><strong>SỨC KHỎE: </strong>Nếu bình thường ít bệnh mà gặp quẻ này thì bất lợi.<br><strong>CÔNG VIỆC:</strong> có nhiều người đố kỵ.'
    },
    {
        position: 35,
        tenque: 'HỎA ĐỊA TẤN',
        nguhanhgiadinhquai: 'KIM',
        huyenkhongnguhanh: 3,
        huyenkhongquaivan: 3,
        thequai: 'DU HỒN QUÁI',
        hoagiap: 'ẤT HỢI',
        haothe: 4,
        haoung: 1,
        diachiquai1: 'Quan Quỷ',
        diachiquai2: 'Tỵ',
        diachiquai3: 'Phụ Mẫu',
        diachiquai4: 'Mùi',
        diachiquai5: 'Huynh Đệ',
        diachiquai6: 'Dậu',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Mão',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Tỵ',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Mùi',
        CodeQuai: 'Lien-Dut-Lien-Dut-Dut-Dut',
        note3: 'Thể',
        note6: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Hãy chấp nhận cùng đi với tập thể . Qua họ bạn sẽ đạt sự thăng tiến trong xã hội.<br><strong>SỰ NGHIỆP:</strong> Cơ hội đang gõ cửa. Hãy bám lấy để thành công.<br><strong>TÀI LỘC:</strong> Sẽ gặp nhiều may mắn. Bên đối tác thể hiện sự chân thành.<br><strong>KINH DOANH:</strong> Địa hạt kinh doanh của bạn sẽ được mở rộng.<br><strong>TÌNH CẢM:</strong> Có tình cảm song phương. Hãy tận dụng cơ hội để bắt đầu một mối quan hệ tình cảm tốt.<br><strong>HỌC HÀNH:</strong> Đã chọn lựa được môn học yêu thích thì bạn hãy hết mình khi học tập để đạt kết quả tốt nhất.<br><strong>SỨC KHỎE: </strong>Tình hình tệ hơn. Chú ý tránh bệnh truyền nhiễm liên quan đến tim mạch và bao tử. Bệnh lâu dài, nguy hiểm. Chú ý bao tử, tim gan.'
    },
    {
        position: 36,
        tenque: 'ĐỊA HỎA MINH DI',
        nguhanhgiadinhquai: 'THỦY',
        huyenkhongnguhanh: 1,
        huyenkhongquaivan: 3,
        thequai: 'DU HỒN QUÁI',
        hoagiap: 'TÂN SỬU',
        haothe: 4,
        haoung: 1,
        diachiquai1: 'Phụ Mẫu',
        diachiquai2: 'Dậu',
        diachiquai3: 'Huynh Đệ',
        diachiquai4: 'Hợi',
        diachiquai5: 'Quan Quỷ',
        diachiquai6: 'Sửu',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Hợi',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Sửu',
        diachiquai11: 'Tử Tôn',
        diachiquai12: 'Mão',
        CodeQuai: 'Dut-Dut-Dut-Lien-Dut-Lien',
        note3: 'Thể',
        note6: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Trong lúc này nên ở nhà câu cá, làm vườn.<br><strong>SỰ NGHIỆP:</strong> Đừng tiến hành mọi việc quá vội vã, bởi vì vận may sẽ đi xuống. Cẩn trọng sẽ giúp giảm khả năng thất bại.<br><strong>TÀI LỘC:</strong> Không khuyến khích mở rộng, thời cơ chưa đến cần phải chờ đợi.<br><strong>KINH DOANH:</strong> Đang ở trong giai đoạn suy thoái.<br><strong>TÌNH CẢM:</strong> Phía bên kia đang lạc quan. Hãy kiên nhẫn và chờ đợi thời khắc đúng đắn trước khi hành động.<br><strong>HỌC HÀNH:</strong> Thiếu mục tiêu rõ ràng và mất kiên nhẫn sẽ làm cho việc tập trung học hành khó khăn hơn và khó tránh việc ảnh hưởng đến kết quả.<br><strong>SỨC KHỎE: </strong>Bạn có thể bị bệnh mà không biết rõ nguyên nhân.'
    },
    {
        position: 37,
        tenque: 'PHONG HỎA GIA NHÂN',
        nguhanhgiadinhquai: 'MỘC',
        huyenkhongnguhanh: 2,
        huyenkhongquaivan: 4,
        thequai: 'NHỊ THẾ QUÁI',
        hoagiap: 'BÍNH DẦN',
        haothe: 2,
        haoung: 5,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Mão',
        diachiquai3: 'Tử Tôn',
        diachiquai4: 'Tỵ',
        diachiquai5: 'Thê Tài',
        diachiquai6: 'Mùi',
        diachiquai7: 'Phụ Mẫu',
        diachiquai8: 'Hợi',
        diachiquai9: 'Thê Tài',
        diachiquai10: 'Sửu',
        diachiquai11: 'Huynh Đệ',
        diachiquai12: 'Mão',
        CodeQuai: 'Lien-Lien-Dut-Lien-Dut-Lien',
        note5: 'Thể',
        note2: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Cần phải có một chuyến du lịch có tính cách gia đình.<br><strong>SỰ NGHIỆP:</strong> Thái độ thân thiện và chân thành sẽ đem đến sự ủng hộ cho bạn từ quý nhân và cấp trên của bạn, đảm bảo sự may mắn thuận lợi trong mọi việc.<br><strong>TÀI LỘC:</strong> Sẽ gặp may mắn. Hãy lắng nghe lời khuyên của người khác trước khi quyết định. Hợp tác với gia đình và bạn bè sẽ đem lại lợi nhuận lớn.<br><strong>KINH DOANH:</strong> Hãy quay lại làm việc với người trong đại gia đình.<br><strong>TÌNH CẢM:</strong> Mối quan hệ thiên duyên tiền định. Hãy phát triển mối quan hệ này.<br><strong>HỌC HÀNH:</strong> Gia đình hỗ trợ sẽ củng cố sự tự tin của bạn<br><strong>SỨC KHỎE: </strong>Không được khinh suất, hãy đi khám sức khoẻ sớm.<br><strong>MỒ MẢ:</strong> tốt ,phát phúc lợi đông nam và nam.'
    },
    {
        position: 38,
        tenque: 'HỎA TRẠCH KHUÊ',
        nguhanhgiadinhquai: 'THỔ',
        huyenkhongnguhanh: 3,
        huyenkhongquaivan: 2,
        thequai: 'TỨ THẾ QUÁI',
        hoagiap: 'GIÁP THÌN',
        haothe: 4,
        haoung: 1,
        diachiquai1: 'Phụ Mẫu',
        diachiquai2: 'Tỵ',
        diachiquai3: 'Huynh Đệ',
        diachiquai4: 'Mùi',
        diachiquai5: 'Tử Tôn',
        diachiquai6: 'Dậu',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Sửu',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Mão',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Tỵ',
        CodeQuai: 'Lien-Dut-Lien-Dut-Lien-Lien',
        note3: 'Thể',
        note6: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> ở đây cũng thế, cần thận trọng.<br><strong>SỰ NGHIỆP:</strong> Ý kiến mâu thuẫn với sếp và đồng nghiệp sẽ dẫn đến tranh cãi không ngừng. Hãy lắng nghe nhiều hơn và cãi nhau ít hơn.<br><strong>TÀI LỘC:</strong> Đừng quá lạc quan, quyết định sai lầm có thể gây thiệt hại lớn. Coi chừng có thể bị mất một số tiền lớn, đề phòng ăn trộm viếng nhà.<br><strong>KINH DOANH:</strong> Như sự đương đầu trong mây khói, hãy thận trọng và suy nghĩ.<br><strong>TÌNH CẢM:</strong> Sự khác biệt trong tính cách gây ra trồi sụt trong mối quan hệ tình cảm.<br><strong>HỌC HÀNH:</strong> Không chuẩn bị đầy đủ và bị phân tâm sẽ khiến bạn phạm sai lầm trong lúc thi cử.<br><strong>SỨC KHỎE: </strong>Chú ý tuần hoàn máu, táo bón và bệnh hô hấp.'
    },
    {
        position: 39,
        tenque: 'THỦY SƠN KIỂN',
        nguhanhgiadinhquai: 'KIM',
        huyenkhongnguhanh: 7,
        huyenkhongquaivan: 2,
        thequai: 'TỨ THẾ QUÁI',
        hoagiap: 'GIÁP TUẤT',
        haothe: 4,
        haoung: 1,
        diachiquai1: 'Tử Tôn',
        diachiquai2: 'Tý',
        diachiquai3: 'Phụ Mẫu',
        diachiquai4: 'Tuất',
        diachiquai5: 'Huynh Đệ',
        diachiquai6: 'Thân',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Thân',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Ngọ',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Thìn',
        CodeQuai: 'Dut-Lien-Dut-Lien-Dut-Dut',
        note3: 'Thể',
        note6: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Do các trở ngại xảy ra, tốt nhất nên hủy bỏ chuyến đi.<br><strong>SỰ NGHIỆP:</strong> Thời điểm đúng vẫn chưa đến.<br><strong>TÀI LỘC:</strong> Hãy giữ nguyên hiện trạng.<br><strong>KINH DOANH:</strong> Các trở ngại trước mặt thúc đẩy bạn cần cẩn trọng, các khó khăn xuất phát từ bạn.<br><strong>TÌNH CẢM:</strong> Bạn nên giao tiếp cởi mở. Mất lòng tin lẫn nhau và sự lãng tránh sẽ chỉ làm cho mối quan hệ tệ hơn.<br><strong>HỌC HÀNH:</strong> Đừng lo lắng nếu bạn gặp trở ngại.<br><strong>SỨC KHỎE: </strong>Tình hình sức khoẻ vẫn không tiến triển nhưng bạn không được từ bỏ liệu pháp điều trị.'
    },
    {
        position: 40,
        tenque: 'LÔI THỦY GIẢI',
        nguhanhgiadinhquai: 'MỘC',
        huyenkhongnguhanh: 8,
        huyenkhongquaivan: 4,
        thequai: 'NHỊ THẾ QUÁI',
        hoagiap: 'BÍNH THÂN',
        haothe: 2,
        haoung: 5,
        diachiquai1: 'Thê Tài',
        diachiquai2: 'Tuất',
        diachiquai3: 'Quan Quỷ',
        diachiquai4: 'Thân',
        diachiquai5: 'Tử Tôn',
        diachiquai6: 'Ngọ',
        diachiquai7: 'Tử Tôn',
        diachiquai8: 'Ngọ',
        diachiquai9: 'Thê Tài',
        diachiquai10: 'Thìn',
        diachiquai11: 'Huynh Đệ',
        diachiquai12: 'Dần',
        CodeQuai: 'Dut-Dut-Lien-Dut-Lien-Dut',
        note5: 'Thể',
        note2: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Sẽ có lợi.<br><strong>SỰ NGHIỆP:</strong> Đây là lúc để chứng tỏ tài năng và khả năng của bạn. Hãy tiến lên mạnh mẽ!<br><strong>TÀI LỘC:</strong> Đang gặp thời cơ tốt để hùn hạp và đầu tư sẽ gặp may mắn.<br><strong>KINH DOANH:</strong> Mọi việc đang trên đà dàn xếp.<br><strong>TÌNH CẢM:</strong> Sự hiểu lầm được giải quyết và tất cả mọi việc đều ổn.<br><strong>HỌC HÀNH:</strong> Công việc vất vả sẽ đem lại thành công.<br><strong>SỨC KHỎE: </strong>Bệnh lâu dài có thể chữa, chú ý tim, vai, chân.<br><strong>MỒ MẢ:</strong> Sau khi sang sửa lại ,con cháu được hưởng phúc.<br><strong>ĐƯỜNG CƠ:</strong> Tượng của gia đình có của hay găp may mắn , nhà có người làm việc thiện hay cúng tế thì tốt , con cháu hưởng phúc.'
    },
    {
        position: 41,
        tenque: 'SƠN TRẠCH TỔN',
        nguhanhgiadinhquai: 'THỔ',
        huyenkhongnguhanh: 6,
        huyenkhongquaivan: 9,
        thequai: 'TAM THẾ QUÁI',
        hoagiap: 'ĐINH MÃO',
        haothe: 3,
        haoung: 6,
        diachiquai1: 'Quan Quỷ',
        diachiquai2: 'Dần',
        diachiquai3: 'Thê Tài',
        diachiquai4: 'Tý',
        diachiquai5: 'Huynh Đệ',
        diachiquai6: 'Tuất',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Sửu',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Mão',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Tỵ',
        CodeQuai: 'Lien-Dut-Dut-Dut-Lien-Lien',
        note4: 'Thể',
        note1: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Không nên xuất hành trong tháng này, có thể tổn hại về mặt vật chất.<br><strong>SỰ NGHIỆP:</strong> Mặc dù đường đi đầy trắc trở, bạn không được bỏ cuộc quá dễ dàng.<br><strong>TÀI LỘC:</strong> Đánh giá sai lầm sẽ dẫn đến kết quả thua lỗ.<br><strong>KINH DOANH:</strong> Đang trải qua thời kỳ suy thoái.<br><strong>TÌNH CẢM:</strong> Người kia chưa sẵn sàng cho một mối quan hệ. Đừng nản lòng.<br><strong>HỌC HÀNH:</strong> Quá tự tin sẽ khiến kết quả kém. Làm việc chăm chỉ hơn để tốt hơn lần sau.<br><strong>SỨC KHỎE: </strong>Tình hình sức khoẻ nghiêm trọng nhưng bạn sẽ phục hồi.'
    },
    {
        position: 42,
        tenque: 'PHONG LÔI ÍCH',
        nguhanhgiadinhquai: 'MỘC',
        huyenkhongnguhanh: 2,
        huyenkhongquaivan: 9,
        thequai: 'TAM THẾ QUÁI',
        hoagiap: 'CANH TÝ',
        haothe: 3,
        haoung: 6,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Mão',
        diachiquai3: 'Tử Tôn',
        diachiquai4: 'Tỵ',
        diachiquai5: 'Thê Tài',
        diachiquai6: 'Mùi',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Thìn',
        diachiquai9: 'Huynh Đệ',
        diachiquai10: 'Dần',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Tý',
        CodeQuai: 'Lien-Lien-Dut-Dut-Dut-Lien',
        note4: 'Thể',
        note1: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Có lợi cho bạn vì đang trong giai đoạn tăng trưởng. những mai mắn luôn chờ đón bạn.<br><strong>SỰ NGHIỆP:</strong> Bạn đang gặt hái thành quả lao động. Rất có lợi nếu lắng nghe lời khuyên từ người lớn tuổi.<br><strong>TÀI LỘC:</strong> Thắng lợi cho cả hai có thể đến từ mối quan hệ tiềm năng. Sẽ gặp may mắn. nên hùn hạp với bạn đồng nghiệp trong công việc làm ăn.<br><strong>KINH DOANH:</strong> Bạn đang phát triển tột bực. Doanh thu tăng sẽ giàu hơn.<br><strong>TÌNH CẢM:</strong> Tình cảm song phương từ hai phía. Đừng ngại ngần trong hành động.<br><strong>HỌC HÀNH:</strong> Bạn có thể thành công với sự giúp đỡ của người thầy tốt và bạn bè.<br><strong>SỨC KHỎE: </strong>Có thể chữa trị, đề phòng can đởm. Sức khoẻ của bạn đang suy giảm.<br><strong>MỒ MẢ:</strong> Bắt đầu kết phát.'
    },
    {
        position: 43,
        tenque: 'TRẠCH THIÊN QUẢI',
        nguhanhgiadinhquai: 'THỔ',
        huyenkhongnguhanh: 4,
        huyenkhongquaivan: 6,
        thequai: 'NGŨ THẾ QUÁI',
        hoagiap: 'QUÝ TỴ',
        haothe: 5,
        haoung: 2,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Mùi',
        diachiquai3: 'Tử Tôn',
        diachiquai4: 'Dậu',
        diachiquai5: 'Thê Tài',
        diachiquai6: 'Hợi',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Thìn',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Dần',
        diachiquai11: 'Thê Tài',
        diachiquai12: 'Tý',
        CodeQuai: 'Dut-Lien-Lien-Lien-Lien-Lien',
        note2: 'Thể',
        note5: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Hãy chấp nhận. Tất cả mọi việc điều được thuận lợi.<br><strong>SỰ NGHIỆP:</strong> Nỗ lực không phải luôn đảm bảo kết quả tốt. Hãy từ từ nếu thời gian chưa phải đúng lúc. Đừng quá căng thẳng.<br><strong>TÀI LỘC:</strong> Cả hai bên khó đạt được thoả thuận.<br><strong>KINH DOANH:</strong> Bạn cần phải có một quyết định cứng rắn, hoặc tiến hoặc lùi.<br><strong>TÌNH CẢM:</strong> Sự thiếu hoà hợp giữa hai bên đều không muốn nhường nhịn sẽ dẫn đến cãi nhau thường xuyên.<br><strong>HỌC HÀNH:</strong> Mặc dù làm việc vất vả, kết quả của bạn không tương xứng với nỗ lực bỏ ra. Hãy thay đổi cách học để thay đổi kết quả tốt hơn.<br><strong>SỨC KHỎE: </strong>Bệnh tình nặng, cần cấp tốc chữa trị, đề phòng phần đầu, hệ hô hấp.'
    },
    {
        position: 44,
        tenque: 'THIÊN PHONG CẤU',
        nguhanhgiadinhquai: 'KIM',
        huyenkhongnguhanh: 9,
        huyenkhongquaivan: 8,
        thequai: 'NHẤT THẾ QUÁI',
        hoagiap: 'GIÁP NGỌ',
        haothe: 1,
        haoung: 4,
        diachiquai1: 'Phụ Mẫu',
        diachiquai2: 'Tuất',
        diachiquai3: 'Huynh Đệ',
        diachiquai4: 'Thân',
        diachiquai5: 'Quan Quỷ',
        diachiquai6: 'Ngọ',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Dậu',
        diachiquai9: 'Tử Tôn',
        diachiquai10: 'Hợi',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Sửu',
        CodeQuai: 'Lien-Lien-Lien-Lien-Lien-Dut',
        note6: 'Thể',
        note3: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Trong một chuyến du ngoạn, bạn có một cuộc gặp gỡ quan trọng liên hệ đến tình cảm hay nghề nghiệp của bạn. coi chừng có thể sẽ bị sa lầy.<br><strong>SỰ NGHIỆP:</strong> Thành tích sáng chói của bạn thiếu sự chỉnh chu, khiến cho các đối thủ của bạn lợi dụng cơ hội. Tuy vậy đừng vội bỏ cuộc.<br><strong>TÀI LỘC:</strong> Các mối kinh doanh thường không như ý vào phút chót.<br><strong>KINH DOANH:</strong> Đề phòng sự cám dỗ. Hãy nghiên cứu kỹ các đề nghị, hãy trông cậy ở mình hơn là ở các cộng sự viên và các người chung quanh.<br><strong>TÌNH CẢM:</strong> Thiếu niềm tin sẽ dẫn đến xuất hiện người thứ ba.<br><strong>HỌC HÀNH:</strong> Bạn có thể hài lòng với kết quả nhưng trên thực tế, nó không đáng hài lòng.<br><strong>SỨC KHỎE: </strong>Bệnh tình nặng, biến đổi ác tính, Hãy cẩn trọng đau đầu, bệnh sưng huyết và bệnh lây.'
    },
    {
        position: 45,
        tenque: 'TRẠCH ĐỊA TỤY',
        nguhanhgiadinhquai: 'KIM',
        huyenkhongnguhanh: 4,
        huyenkhongquaivan: 4,
        thequai: 'NHỊ THẾ QUÁI',
        hoagiap: 'NHÂM TUẤT',
        haothe: 2,
        haoung: 5,
        diachiquai1: 'Phụ Mẫu',
        diachiquai2: 'Mùi',
        diachiquai3: 'Huynh Đệ',
        diachiquai4: 'Dậu',
        diachiquai5: 'Tử Tôn',
        diachiquai6: 'Hợi',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Mão',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Tỵ',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Mùi',
        CodeQuai: 'Dut-Lien-Lien-Dut-Dut-Dut',
        note5: 'Thể',
        note2: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Bạn có thể chấp nhận với điều kiện phải đi cả nhóm.<br><strong>SỰ NGHIỆP:</strong> Nỗ lực của bạn sẽ được công nhận.<br><strong>TÀI LỘC:</strong> Nếu hợp tác đầu tư sẽ có lợi, nhưng tránh vụ lợi cá nhân mà cần vì tập thể, có như vậy mới thành công.<br><strong>KINH DOANH:</strong> Sẽ có lợi nếu hợp tác được với mọi người.<br><strong>TÌNH CẢM:</strong> Bạn có thể gặp đối tác tiềm năng bằng cách tham gia vào các hoạt động của hội độc thân.<br><strong>HỌC HÀNH:</strong> Nếu bạn chuẩn bị thật tốt, bạn có khả năng cao thể hiện tốt nhất năng lực trong bài thi.<br><strong>SỨC KHỎE: </strong>Bệnh nặng liên quan đến cổ họng, ngực và bụng. Hãy đi khám ngay.'
    },
    {
        position: 46,
        tenque: 'ĐỊA PHONG THĂNG',
        nguhanhgiadinhquai: 'MỘC',
        huyenkhongnguhanh: 1,
        huyenkhongquaivan: 2,
        thequai: 'TỨ THẾ QUÁI',
        hoagiap: 'KỶ MÙI',
        haothe: 4,
        haoung: 1,
        diachiquai1: 'Quan Quỷ',
        diachiquai2: 'Dậu',
        diachiquai3: 'Phụ Mẫu',
        diachiquai4: 'Hợi',
        diachiquai5: 'Thê Tài',
        diachiquai6: 'Sửu',
        diachiquai7: 'Quan Quỷ',
        diachiquai8: 'Dậu',
        diachiquai9: 'Phụ Mẫu',
        diachiquai10: 'Hợi',
        diachiquai11: 'Thê Tài',
        diachiquai12: 'Sửu',
        CodeQuai: 'Dut-Dut-Dut-Lien-Lien-Dut',
        note3: 'Thể',
        note6: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Sự thăng tiến là cơ hội cho một sự bổ nhiệm, một sự may mắn, đi du lịch bạn sẽ vui vẻ.<br><strong>SỰ NGHIỆP:</strong> Dấu hiệu thăng tiến. Tuy nhiên, bản cần tránh hành động thiếu kiên nhẫn.<br><strong>TÀI LỘC:</strong> Có thể bị hao tài trong giai đoạn đầu, nhưng cuối cùng sẽ đạt kết quả như mong muốn.<br><strong>KINH DOANH:</strong> Sự thăng tiến biểu hiện sự thành công.<br><strong>TÌNH CẢM:</strong> Hãy kiên nhẫn để từ sự quan tâm lẫn nhau mà gây dựng nên tình cảm yêu thương lâu dài.<br><strong>HỌC HÀNH:</strong> Kết quả sẽ cải thiện với nỗ lực chăm chỉ và trợ giúp từ người thầy và đồng nghiệp của bạn.<br><strong>SỨC KHỎE: </strong>Bệnh tình nặng, chú ý phần bao tử, gan. Tình hình có xu hướng tệ hơn.'
    },
    {
        position: 47,
        tenque: 'TRẠCH THỦY KHỐN',
        nguhanhgiadinhquai: 'KIM',
        huyenkhongnguhanh: 4,
        huyenkhongquaivan: 8,
        thequai: 'NHẤT THẾ QUÁI',
        hoagiap: 'QUÝ MÙI',
        haothe: 1,
        haoung: 4,
        diachiquai1: 'Phụ Mẫu',
        diachiquai2: 'Mùi',
        diachiquai3: 'Huynh Đệ',
        diachiquai4: 'Dậu',
        diachiquai5: 'Tử Tôn',
        diachiquai6: 'Hợi',
        diachiquai7: 'Quan Quỷ',
        diachiquai8: 'Ngọ',
        diachiquai9: 'Phụ Mẫu',
        diachiquai10: 'Thìn',
        diachiquai11: 'Thê Tài',
        diachiquai12: 'Dần',
        CodeQuai: 'Dut-Lien-Lien-Dut-Lien-Dut',
        note6: 'Thể',
        note3: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Trong giai đoạn ích thuận lợi này bạn nên tránh đi xa rất bất lợi.<br><strong>SỰ NGHIỆP:</strong> Hãy chờ đợi thời điểm đúng. Hãy tự đào tạo cho bản thân trong khi chờ thời.<br><strong>TÀI LỘC:</strong> Coi chừng mất mát (bị giật hoặc ăn trộm viếng nhà}, chưa gặp may mắn.<br><strong>KINH DOANH:</strong> Xấu, ở đây có sự có khánh tận, suy yếu.<br><strong>TÌNH CẢM:</strong> Hiểu lầm và chiến tranh lạnh sẽ chỉ khiến tình hình xấu đi.<br><strong>HỌC HÀNH:</strong> Kém tự tin sẽ dẫn đến thiếu động lực để học tập.<br><strong>SỨC KHỎE: </strong>Bệnh nghiêm trọng, chú ý phần tiết niệu, cổ họng, bao tử.'
    },
    {
        position: 48,
        tenque: 'THỦY PHONG TỈNH',
        nguhanhgiadinhquai: 'MỘC',
        huyenkhongnguhanh: 7,
        huyenkhongquaivan: 6,
        thequai: 'NGŨ THẾ QUÁI',
        hoagiap: 'ẤT MÙI',
        haothe: 5,
        haoung: 2,
        diachiquai1: 'Phụ Mẫu',
        diachiquai2: 'Tý',
        diachiquai3: 'Thê Tài',
        diachiquai4: 'Tuất',
        diachiquai5: 'Quan Quỷ',
        diachiquai6: 'Thân',
        diachiquai7: 'Quan Quỷ',
        diachiquai8: 'Dậu',
        diachiquai9: 'Phụ Mẫu',
        diachiquai10: 'Hợi',
        diachiquai11: 'Thê Tài',
        diachiquai12: 'Sửu',
        CodeQuai: 'Dut-Lien-Dut-Lien-Lien-Dut',
        note2: 'Thể',
        note5: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Đừng chạy theo bề nổi. du lịch hun đúc tuổi trẻ.<br><strong>SỰ NGHIỆP:</strong> Đừng nản lòng bởi khó khăn liên tục. Thất bại là mẹ thành công.<br><strong>TÀI LỘC:</strong> Cần phải kiên trì hơn nữa thì những mong ước sẽ thành đạt như ý muốn.<br><strong>KINH DOANH:</strong> Tránh sự dự đoán để rồi ngần ngại không dám thực hiện các ý tưởng của bạn. Tránh sự tùy tiện.<br><strong>TÌNH CẢM:</strong> Mặc cho mọi nỗ lực của bạn, tình cảm của bạn không được đáp lại.<br><strong>HỌC HÀNH:</strong> Mặc dù cố gắng vất vả, kết quả không như mong đợi. Hãy tiếp tục nỗ lực cho kết quả tốt hơn.<br><strong>SỨC KHỎE: </strong>Bệnh tình nhẹ nhưng chữa trị kéo dài. - vân thê : cần xem xét lại các công việc cũ , chờ thời cơ đến là phat huy.<br><strong>MỒ MẢ:</strong> Nên sửa sang tu lại phần mộ ,sẽ tốt cho con cháu.<br><strong>ĐƯỜNG CƠ:</strong> Nhà cũ , lên sửa sang lại.'
    },
    {
        position: 49,
        tenque: 'TRẠCH HỎA CÁCH',
        nguhanhgiadinhquai: 'THỦY',
        huyenkhongnguhanh: 4,
        huyenkhongquaivan: 2,
        thequai: 'TỨ THẾ QUÁI',
        hoagiap: 'CANH DẦN',
        haothe: 4,
        haoung: 1,
        diachiquai1: 'Quan Quỷ',
        diachiquai2: 'Mùi',
        diachiquai3: 'Phụ Mẫu',
        diachiquai4: 'Dậu',
        diachiquai5: 'Huynh Đệ',
        diachiquai6: 'Hợi',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Hợi',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Sửu',
        diachiquai11: 'Tử Tôn',
        diachiquai12: 'Mão',
        CodeQuai: 'Dut-Lien-Lien-Lien-Dut-Lien',
        note6: 'Ứng',
        note3: 'Thể',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Rất quan trọng trong cuộc sống của bạn, đặc biệt là về địa hạt trí thức.<br><strong>SỰ NGHIỆP:</strong> Điều chỉnh Theo xu hướng thay đổi sẽ giúp đạt thành công.<br><strong>TÀI LỘC:</strong> Có thể bỏ tiền ra làm ăn, sẽ có lợi trong thời điểm này.<br><strong>KINH DOANH:</strong> Bạn sẽ thành công với điều kiện là phải lập kế hoạch cụ thể.<br><strong>TÌNH CẢM:</strong> Dù lỗi nhỏ, bên kia có ấn tượng tốt về bạn. Hãy nỗ lực để cải thiện bản thân.<br><strong>HỌC HÀNH:</strong> Hãy xem lại cách học để cải thiện điểm số.<br><strong>SỨC KHỎE: </strong>Hãy đi thăm khám bác sĩ hay tìm kiếm các cách chữa trị có thể giúp cải thiện tình hình của bạn.'
    },
    {
        position: 50,
        tenque: 'HỎA PHONG ĐỈNH',
        nguhanhgiadinhquai: 'HỎA',
        huyenkhongnguhanh: 3,
        huyenkhongquaivan: 4,
        thequai: 'NHỊ THẾ QUÁI',
        hoagiap: 'MẬU NGỌ',
        haothe: 2,
        haoung: 5,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Tỵ',
        diachiquai3: 'Tử Tôn',
        diachiquai4: 'Mùi',
        diachiquai5: 'Thê Tài',
        diachiquai6: 'Dậu',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Dậu',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Hợi',
        diachiquai11: 'Tử Tôn',
        diachiquai12: 'Sửu',
        CodeQuai: 'Lien-Dut-Lien-Lien-Lien-Dut',
        note5: 'Thể',
        note2: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Tuyệt vời.<br><strong>SỰ NGHIỆP:</strong> Hãy mạnh mẽ đề xuất chiến lược và ý tưởng mới.<br><strong>TÀI LỘC:</strong> Bạn sắp có một số tiền lớn.<br><strong>KINH DOANH:</strong> Bạn sẽ thành công, mức sống trong xã hội của bạn sẽ đạt mức độ khả quan. Điều tốt cho bạn là dưới mắt bạn yếu tố tinh thần quan trọng hơn là vật chất.<br><strong>TÌNH CẢM:</strong> Mối quan hệ đang tốt và được mọi người xung quanh chúc phúc.<br><strong>HỌC HÀNH:</strong> Khả năng học tập mạnh mẽ kết hợp với chăm chỉ, sẽ giúp bạn đạt thành công tột đỉnh.<br><strong>SỨC KHỎE: </strong>Bạn sẽ không bị bệnh nặng.<br><strong>NHÀ CỬA:</strong> Hợp với người tay tứ trạch'
    },
    {
        position: 51,
        tenque: 'CHẤN VI LÔI',
        nguhanhgiadinhquai: 'MỘC',
        huyenkhongnguhanh: 8,
        huyenkhongquaivan: 1,
        thequai: 'BÁT THUẦN QUÁI',
        hoagiap: 'NHÂM TÝ',
        haothe: 6,
        haoung: 3,
        diachiquai1: 'Thê Tài',
        diachiquai2: 'Tuất',
        diachiquai3: 'Quan Quỷ',
        diachiquai4: 'Thân',
        diachiquai5: 'Tử Tôn',
        diachiquai6: 'Ngọ',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Thìn',
        diachiquai9: 'Huynh Đệ',
        diachiquai10: 'Dần',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Tý',
        CodeQuai: 'Dut-Dut-Lien-Dut-Dut-Lien',
        note1: 'Thể',
        note4: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> ở đây cũng thế , có sự bất ngờ xảy đến. hãy thận trọng.<br><strong>SỰ NGHIỆP:</strong> Dù có một khởi đầu tuyệt vời, sự mất kiên nhẫn của bạn thường dẫn đến kết thúc thảm bại và mất cơ hội.<br><strong>TÀI LỘC:</strong> Đừng quá nôn nóng để thành công.<br><strong>KINH DOANH:</strong> Các đợt sấm sét nghĩa là các sự kiện bất thần xảy đến.<br><strong>TÌNH CẢM:</strong> Để giảm thiểu cãi nhau cần phải có một tính cách mạnh mẽ, bạn phải chú ý phát ngôn và học cách trân trọng đối phương.<br><strong>HỌC HÀNH:</strong> Cảm xúc bất ổn ảnh hưởng đến điểm số. Hãy tập kiểm soát bản thân.<br><strong>SỨC KHỎE: </strong>Tránh tâm trạng thất thường, mất ngủ và bệnh liên quan phần lá lách.'
    },
    {
        position: 52,
        tenque: 'CẤN VI SƠN',
        nguhanhgiadinhquai: 'THỔ',
        huyenkhongnguhanh: 6,
        huyenkhongquaivan: 1,
        thequai: 'BÁT THUẦN QUÁI',
        hoagiap: 'BÍNH TUẤT',
        haothe: 6,
        haoung: 3,
        diachiquai1: 'Quan Quỷ',
        diachiquai2: 'Dần',
        diachiquai3: 'Thê Tài',
        diachiquai4: 'Tý',
        diachiquai5: 'Huynh Đệ',
        diachiquai6: 'Tuất',
        diachiquai7: 'Tử Tôn',
        diachiquai8: 'Thân',
        diachiquai9: 'Phụ Mẫu',
        diachiquai10: 'Ngọ',
        diachiquai11: 'Huynh Đệ',
        diachiquai12: 'Thìn',
        CodeQuai: 'Lien-Dut-Dut-Lien-Dut-Dut',
        note1: 'Thể',
        note4: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Không khuyến khích, tốt nhất là nên ở nhà.<br><strong>SỰ NGHIỆP:</strong> Tình huống khó khăn khiến bạn không thể thăng tiến trong công việc.<br><strong>TÀI LỘC:</strong> Không thể đạt được thoả thuận. Cần đợi thời cơ đúng xuất hiện.<br><strong>TÌNH CẢM:</strong> Cả hai bên quá rụt rè. Cần phải đột phá thì mới mong đẩy nhanh mối quan hệ này.<br><strong>HỌC HÀNH:</strong> Kiên trì học theo cách không hiệu quả sẽ tạo ra kết quả tệ.<br><strong>SỨC KHỎE: </strong>Khó chữa. Khó để hồi phục trong ngắn hạn.<br><strong>MỒ MẢ:</strong> Mộ không di dời , mồ kết.<br><strong>ĐƯỜNG CƠ:</strong> nhà cũ ,tối ,cần sửa sang tiết mình cho mới - sẽ tốt.'
    },
    {
        position: 53,
        tenque: 'PHONG SƠN TIỆM',
        nguhanhgiadinhquai: 'THỔ',
        huyenkhongnguhanh: 2,
        huyenkhongquaivan: 7,
        thequai: 'QUY HỒN QUÁI',
        hoagiap: 'QUÝ DẬU',
        haothe: 3,
        haoung: 6,
        diachiquai1: 'Quan Quỷ',
        diachiquai2: 'Mão',
        diachiquai3: 'Phụ Mẫu',
        diachiquai4: 'Tỵ',
        diachiquai5: 'Huynh Đệ',
        diachiquai6: 'Mùi',
        diachiquai7: 'Tử Tôn',
        diachiquai8: 'Thân',
        diachiquai9: 'Phụ Mẫu',
        diachiquai10: 'Ngọ',
        diachiquai11: 'Huynh Đệ',
        diachiquai12: 'Thìn',
        CodeQuai: 'Lien-Lien-Dut-Lien-Dut-Dut',
        note4: 'Thể',
        note1: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Hãy tham gia. Đừng nên chờ đợi các kết quả tức thì, lợi nhuận, lợi nhuận sẽ chậm và khó khăn.<br><strong>SỰ NGHIỆP:</strong> Hãy tỉnh táo và tiếp tục làm việc hăng say.<br><strong>TÀI LỘC:</strong> Kiên nhẫn và tin tưởng đối tác để cùng nhau thành công về tài chính.<br><strong>TÌNH CẢM:</strong> Mối quan hệ ban đầu không suôn sẻ nhưng đừng mất kiên nhẫn.<br><strong>HỌC HÀNH:</strong> Hãy tiếp tục siêng năng để xây dựng nền tảng thật tốt.<br><strong>SỨC KHỎE: </strong>Hãy chú ý hệ tiêu hoá và thần kinh'
    },
    {
        position: 54,
        tenque: 'LÔI TRẠCH QUY MUỘI',
        nguhanhgiadinhquai: 'KIM',
        huyenkhongnguhanh: 8,
        huyenkhongquaivan: 7,
        thequai: 'QUY HỒN QUÁI',
        hoagiap: 'QUÝ MÃO',
        haothe: 3,
        haoung: 6,
        diachiquai1: 'Phụ Mẫu',
        diachiquai2: 'Tuất',
        diachiquai3: 'Huynh Đệ',
        diachiquai4: 'Thân',
        diachiquai5: 'Quan Quỷ',
        diachiquai6: 'Ngọ',
        diachiquai7: 'Phụ Mẫu',
        diachiquai8: 'Sửu',
        diachiquai9: 'Thê Tài',
        diachiquai10: 'Mão',
        diachiquai11: 'Quan Quỷ',
        diachiquai12: 'Tỵ',
        CodeQuai: 'Dut-Dut-Lien-Dut-Lien-Lien',
        note4: 'Thể',
        note1: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Đừng làm gì và đi du lịch ở đâu cả, nhiều yếu tố đam mê và lệch lạc làm lu mờ sự nhận định của bạn.<br><strong>SỰ NGHIỆP:</strong> Nhớ giữ sự hoà hợp, tránh vội vàng mà phá hỏng sự nghiệp của mình.<br><strong>TÀI LỘC:</strong> Đừng tham gia vào những hoạt động phi pháp, mờ ám. Nếu không, bạn sẽ phải hối hận về sau. Nên để dành tiền, không nên đầu tư hoặc cho ai mượn sẽ mất mát.<br><strong>KINH DOANH:</strong> Điều chỉnh các nhận xét của bạn, bạn đang ở trong mọi vị thế bất lợi, giống như chất xúc tác trong một phản ứng hóa học.<br><strong>TÌNH CẢM:</strong> Cẩn trọng tránh người thứ ba trong mối quan hệ hay trở thành kẻ thứ ba.<br><strong>HỌC HÀNH:</strong> Đừng đánh giá khả năng mình quá cao và thiếu chuẩn bị đầy đủ; bạn sẽ khó tránh kết quả học tập kém.<br><strong>SỨC KHỎE: </strong>Tình hình tệ hơn sẽ không thể điều trị bằng thuốc được.'
    },
    {
        position: 55,
        tenque: 'LÔI HỎA PHONG',
        nguhanhgiadinhquai: 'THỦY',
        huyenkhongnguhanh: 8,
        huyenkhongquaivan: 6,
        thequai: 'NGŨ THẾ QUÁI',
        hoagiap: 'MẬU DẦN',
        haothe: 5,
        haoung: 2,
        diachiquai1: 'Quan Quỷ',
        diachiquai2: 'Tuất',
        diachiquai3: 'Phụ Mẫu',
        diachiquai4: 'Thân',
        diachiquai5: 'Thê Tài',
        diachiquai6: 'Ngọ',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Hợi',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Sửu',
        diachiquai11: 'Tử Tôn',
        diachiquai12: 'Mão',
        CodeQuai: 'Dut-Dut-Lien-Lien-Dut-Lien',
        note2: 'Thể',
        note5: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Sẽ có lợi bạn sẽ hưởng về cái tốt.<br><strong>SỰ NGHIỆP:</strong> Thăng tiến và tăng lương sẽ đem lại niềm vui cho bạn tuy nhiên bạn không được tự mãn. Cần tiếp tục cố gắng.<br><strong>TÀI LỘC:</strong> Sẽ gặp được nhiều may mắn. Thành công sẽ đem lại lợi nhuận. Giữ mối quan hệ tốt để đem lại hợp tác thành công lâu dài.<br><strong>KINH DOANH:</strong> Mọi việc điều tốt đẹp với bạn, đạt từ thành công này tới thành công khác , bạn sẽ nhận định đúng các việc và điều này giúp ích rất nhiều cho bạn.<br><strong>TÌNH CẢM:</strong> Mặc dù tình cảm ổn định, bạn không được xem thường bạn đời của mình.<br><strong>HỌC HÀNH:</strong> Kết quả học tập của bạn và các hoạt động ngoại khoá của bạn đạt thành tích xuất sắc.<br><strong>SỨC KHỎE: </strong>Tình hình không nhiều hứa hẹn. Cần quan sát thật kỹ.'
    },
    {
        position: 56,
        tenque: 'HỎA SƠN LỮ',
        nguhanhgiadinhquai: 'HỎA',
        huyenkhongnguhanh: 3,
        huyenkhongquaivan: 8,
        thequai: 'NHẤT THẾ QUÁI',
        hoagiap: 'KỶ DẬU',
        haothe: 1,
        haoung: 4,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Tỵ',
        diachiquai3: 'Tử Tôn',
        diachiquai4: 'Mùi',
        diachiquai5: 'Thê Tài',
        diachiquai6: 'Dậu',
        diachiquai7: 'Thê Tài',
        diachiquai8: 'Thân',
        diachiquai9: 'Huynh Đệ',
        diachiquai10: 'Ngọ',
        diachiquai11: 'Tử Tôn',
        diachiquai12: 'Thìn',
        CodeQuai: 'Lien-Dut-Lien-Lien-Dut-Dut',
        note6: 'Thể',
        note3: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Rất cần trong cuộc sống của bạn. diễn ra thường xuyên.<br><strong>SỰ NGHIỆP:</strong> Để thích hợp cho môi trường mới, bạn phải tránh khoe khoang nhiều.<br><strong>TÀI LỘC:</strong> Đừng sợ hãi nếu bạn chưa hiểu được xu hướng đang thay đổi.<br><strong>KINH DOANH:</strong> Bạn không mảy mai quan tâm đến công việc, đừng quên rằng từng việc nhỏ trong cuộc đời đều có giá trị riêng của nó.<br><strong>TÌNH CẢM:</strong> Thay vì cãi nhau, bạn nên học cách trân trọng quan điểm đối phương.<br><strong>HỌC HÀNH:</strong> Sự mất tập trung trong học tập sẽ dẫn đến kết quả học tập kém.<br><strong>SỨC KHỎE: </strong>Bệnh tình biến hóa không ổn định, nên chữa thật nhanh.'
    },
    {
        position: 57,
        tenque: 'TỐN VI PHONG',
        nguhanhgiadinhquai: 'MỘC',
        huyenkhongnguhanh: 2,
        huyenkhongquaivan: 1,
        thequai: 'BÁT THUẦN QUÁI',
        hoagiap: 'NHÂM NGỌ',
        haothe: 6,
        haoung: 3,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Mão',
        diachiquai3: 'Tử Tôn',
        diachiquai4: 'Tỵ',
        diachiquai5: 'Thê Tài',
        diachiquai6: 'Mùi',
        diachiquai7: 'Quan Quỷ',
        diachiquai8: 'Dậu',
        diachiquai9: 'Phụ Mẫu',
        diachiquai10: 'Hợi',
        diachiquai11: 'Thê Tài',
        diachiquai12: 'Sửu',
        CodeQuai: 'Lien-Lien-Dut-Lien-Lien-Dut',
        note1: 'Thể',
        note4: 'Ứng',
        vieccanhoi:
            '<strong>VẬN THẾ:</strong> mọi việc thuận lợi , được người khác phái quý mến, cân trọng trong các cuộc vui, tửu sắc vừa đủ.<br><strong>TƯƠNG LAI:</strong> Không chủ quan thì mọi việc như ý.<br><strong>TÀI LỘC:</strong> Có tiền, khá đầy đủ dư dả.<br><strong>SỰ NGHIỆP:</strong> Thuận lợi.<br><strong>HỌC TẬP <strong>THI CỬ:</strong></strong> Đạt kết quả bình thường<br><strong>DU LỊCH:</strong> bạn có một chuyến đi chơi vui vẻ, hợp đi về phía Tây thành phố<br><strong>MỒ MẢ:</strong> lâm phụ mẫu thì cần xem xét về mồ mả , nhìn trung khá ở nơi đất tốt.<br><strong>SỨC KHỎE: </strong>cẩn thận chân tay.'
    },
    {
        position: 58,
        tenque: 'ĐOÀI VI TRẠCH',
        nguhanhgiadinhquai: 'KIM',
        huyenkhongnguhanh: 4,
        huyenkhongquaivan: 1,
        thequai: 'BÁT THUẦN QUÁI',
        hoagiap: 'BÍNH THÌN',
        haothe: 6,
        haoung: 3,
        diachiquai1: 'Phụ Mẫu',
        diachiquai2: 'Mùi',
        diachiquai3: 'Huynh Đệ',
        diachiquai4: 'Dậu',
        diachiquai5: 'Tử Tôn',
        diachiquai6: 'Hợi',
        diachiquai7: 'Phụ Mẫu',
        diachiquai8: 'Sửu',
        diachiquai9: 'Thê Tài',
        diachiquai10: 'Mão',
        diachiquai11: 'Quan Quỷ',
        diachiquai12: 'Tỵ',
        CodeQuai: 'Dut-Lien-Lien-Dut-Lien-Lien',
        note1: 'Thể',
        note4: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Thực hiện trong vui vẻ , tất cả điều có lợi.<br><strong>SỰ NGHIỆP:</strong> Sự hỗ trợ từ quý nhân và tài năng của bạn sẽ giúp bạn thành công.<br><strong>TÀI LỘC:</strong> Đừng bỏ đi sau khi gặt hái vài mối lợi nhỏ.<br><strong>TÌNH CẢM:</strong> Đời sống hôn nhân sẽ khác với giai đoạn hẹn hò.<br><strong>HỌC HÀNH:</strong> Đừng nghỉ ngơi dễ dàng sau khi thi đỗ. Hãy tiếp tục cố gắng hơn!<br><strong>SỨC KHOẺ:</strong> Đừng quá sa đà vào ăn nhậu và tình dục.<br><strong>SINH CON:</strong> Dễ có.'
    },
    {
        position: 59,
        tenque: 'PHONG THỦY HOÁN',
        nguhanhgiadinhquai: 'HỎA',
        huyenkhongnguhanh: 2,
        huyenkhongquaivan: 6,
        thequai: 'NGŨ THẾ QUÁI',
        hoagiap: 'MẬU THÂN',
        haothe: 5,
        haoung: 2,
        diachiquai1: 'Phụ Mẫu',
        diachiquai2: 'Mão',
        diachiquai3: 'Huynh Đệ',
        diachiquai4: 'Tỵ',
        diachiquai5: 'Tử Tôn',
        diachiquai6: 'Mùi',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Ngọ',
        diachiquai9: 'Tử Tôn',
        diachiquai10: 'Thìn',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Dần',
        CodeQuai: 'Lien-Lien-Dut-Dut-Lien-Dut',
        note2: 'Thể',
        note5: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Nên lợi dụng nhưng đừng quá chờ đợi cấc lợi lộc vật chất.<br><strong>SỰ NGHIỆP:</strong> Khi thị trường nhân lực có sự biến đổi, bạn cần phải điều chỉnh để phù hợp với yêu cầu công việc mới.<br><strong>TÀI LỘC:</strong> Nhờ vào đầu tư, bạn sẽ thu được nhiều tiền.<br><strong>KINH DOANH:</strong> Có thể thực hiện được công việc làm ăn, nhưng cần phải tránh xa nơi có bạo lực.<br><strong>TÌNH CẢM:</strong> Để tìm được điểm chung với người kia, bạn cần phải quyết tâm và sáng tạo trong việc theo đuổi.<br><strong>HỌC HÀNH:</strong> Hãy tìm cách học khôn ngoan để đạt được điểm thi tốt hơn.<br><strong>SỨC KHỎE: </strong>Bạn sẽ phục hồi sức khoẻ khi được điều trị tốt.'
    },
    {
        position: 60,
        tenque: 'THỦY TRẠCH TIẾT',
        nguhanhgiadinhquai: 'THỦY',
        huyenkhongnguhanh: 7,
        huyenkhongquaivan: 8,
        thequai: 'NHẤT THẾ QUÁI',
        hoagiap: 'KỶ MÃO',
        haothe: 1,
        haoung: 4,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Tý',
        diachiquai3: 'Quan Quỷ',
        diachiquai4: 'Tuất',
        diachiquai5: 'Phụ Mẫu',
        diachiquai6: 'Thân',
        diachiquai7: 'Quan Quỷ',
        diachiquai8: 'Sửu',
        diachiquai9: 'Tử Tôn',
        diachiquai10: 'Mão',
        diachiquai11: 'Thê Tài',
        diachiquai12: 'Tỵ',
        CodeQuai: 'Dut-Lien-Dut-Dut-Lien-Lien',
        note6: 'Thể',
        note3: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Hãy từ chối nên giới hạn.<br><strong>SỰ NGHIỆP:</strong> Thời điểm chưa chín muồi. Hãy cẩn trọng phát ngôn và hành động. Tiến hành thật cẩn trọng.<br><strong>TÀI LỘC:</strong> Phải hạn chế chi tiêu một cách phung phí, sử dụng đồng tiền đúng chỗ.<br><strong>KINH DOANH:</strong> Nên giới hạn . Đây là thời điểm để làm một công cuộc tổng kết, bất động và thiếu suy nghĩ đều rất nguy hiểm.<br><strong>TÌNH CẢM:</strong> Thân mật quá dẫn đến sợ mất tự do.<br><strong>HỌC HÀNH:</strong> Hãy tự biết giới hạn của bản thân. Đừng để các hoạt động ngoại khoá và hoạt động xã hội ảnh hưởng kết quả học tập.<br><strong>SỨC KHỎE: </strong>Chú ý bệnh tật phần tiết niệu, tiêu hóa, Chế độ ăn uống thiếu lành mạnh sẽ gây ra chứng.'
    },
    {
        position: 61,
        tenque: 'PHONG TRẠCH TRUNG PHU',
        nguhanhgiadinhquai: 'THỔ',
        huyenkhongnguhanh: 2,
        huyenkhongquaivan: 3,
        thequai: 'DU HỒN QUÁI',
        hoagiap: 'TÂN MÃO',
        haothe: 4,
        haoung: 1,
        diachiquai1: 'Quan Quỷ',
        diachiquai2: 'Mão',
        diachiquai3: 'Phụ Mẫu',
        diachiquai4: 'Tỵ',
        diachiquai5: 'Huynh Đệ',
        diachiquai6: 'Mùi',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Sửu',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Mão',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Tỵ',
        CodeQuai: 'Lien-Lien-Dut-Dut-Lien-Lien',
        note3: 'Thể',
        note6: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Sẽ có lợi , đây là thời điểm thích hợp để hành động.<br><strong>SỰ NGHIỆP:</strong> Vận may của bạn phụ thuộc vào mối quan hệ cá nhân.<br><strong>TÀI LỘC:</strong> Sẽ gặp may mắn, nhưng chưa có nhiều tiền.<br><strong>KINH DOANH:</strong> Bạn sẽ đạt sự thành công, đừng ngần ngại.<br><strong>TÌNH CẢM:</strong> Tình cảm chân thành sẽ được đền đáp.<br><strong>HỌC HÀNH:</strong> Điều chỉnh và giúp đỡ người khác có thể làm cho bạn đạt kết quả tốt.<br><strong>SỨC KHỎE: </strong>Cẩn trọng tránh sốt, táo bón, bệnh tim và bệnh liên quan thần kinh.<br><strong>MỒ MẢ:</strong> Xem lại âm phần , con cháu dễ bi bệnh thần kinh hay hoang tưởng.'
    },
    {
        position: 62,
        tenque: 'LÔI SƠN TIỂU QUÁ',
        nguhanhgiadinhquai: 'KIM',
        huyenkhongnguhanh: 8,
        huyenkhongquaivan: 3,
        thequai: 'DU HỒN QUÁI',
        hoagiap: 'TÂN DẬU',
        haothe: 4,
        haoung: 1,
        diachiquai1: 'Phụ Mẫu',
        diachiquai2: 'Tuất',
        diachiquai3: 'Huynh Đệ',
        diachiquai4: 'Thân',
        diachiquai5: 'Quan Quỷ',
        diachiquai6: 'Ngọ',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Thân',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Ngọ',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Thìn',
        CodeQuai: 'Dut-Dut-Lien-Lien-Dut-Dut',
        note3: 'Thể',
        note6: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Hãy từ từ mà đi, phải nghĩ đến túi tiền của bạn.<br><strong>SỰ NGHIỆP:</strong> Mục tiêu nhỏ dễ đạt được, ham muốn lớn cần thêm nhiều thời gian.<br><strong>TÀI LỘC:</strong> Đừng quyết định vội vã, hãy chờ đợi và cân nhắc tránh mắc sai lầm.<br><strong>KINH DOANH:</strong> Có những điều tuy nhỏ nhưng lại rất quan trọng.<br><strong>TÌNH CẢM:</strong> Cãi nhau không giúp giải quyết vấn đề. Hãy khuyến khích người yêu hay bạn đời của bạn thay đổi nếu cần thiết.<br><strong>HỌC HÀNH:</strong> Sự tự mãn, chuẩn bị kém và sự cẩu thả sẽ làm cho kết quả học tập sút kém.<br><strong>SỨC KHỎE: </strong>Đừng trì hoãn việc chữa trị. Hảy cẩn trọng bệnh ung thư. Bệnh tình có biến chuyển, nếu chữa kịp thời thì tốt, đề phòng tay chân, bao tử, ruột già.'
    },
    {
        position: 63,
        tenque: 'THỦY HỎA KÝ TẾ',
        nguhanhgiadinhquai: 'THỦY',
        huyenkhongnguhanh: 7,
        huyenkhongquaivan: 9,
        thequai: 'TAM THẾ QUÁI',
        hoagiap: 'GIÁP DẦN',
        haothe: 3,
        haoung: 6,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Tý',
        diachiquai3: 'Quan Quỷ',
        diachiquai4: 'Tuất',
        diachiquai5: 'Phụ Mẫu',
        diachiquai6: 'Thân',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Hợi',
        diachiquai9: 'Quan Quỷ',
        diachiquai10: 'Sửu',
        diachiquai11: 'Tử Tôn',
        diachiquai12: 'Mão',
        CodeQuai: 'Dut-Lien-Dut-Lien-Dut-Lien',
        note4: 'Thể',
        note1: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Hãy lợi dụng giây phút hiện tại. vui hưởng sự an lành của tình trạng bạn vừa đạt được nhưng nên đề phòng, sự bất ngờ khi bạn đi xa.<br><strong>SỰ NGHIỆP:</strong> Bạn đã đạt được mục tiêu. Tuy nhiên, bạn phải tiếp tục làm việc hết mình để duy trì địa vị.<br><strong>TÀI LỘC:</strong> Thành công trước mắt nhưng nguy cơ cũng cao. Đừng hành động vội vã.<br><strong>KINH DOANH:</strong> Sự tiến triển là bắt buộc, hãy dự kiến đến đều bất thường có thể xảy ra mà bình thường bạn không thể nghĩ trước.<br><strong>TÌNH CẢM:</strong> Tình cảm song phương sẽ thăng hoa mối quan hệ. Tuy nhiên, đam mê sẽ dần phai nhạt nếu tình cảm đạt đỉnh điểm.<br><strong>HỌC HÀNH:</strong> Kết quả của bạn rất tốt, hãy tiếp tục đễ giữ vững phong độ học tập.<br><strong>SỨC KHỎE: </strong>Bệnh tật trong ngắn hạn có kết quả tốt, nhưng bất thường.<br><strong>MỒ MẢ:</strong> Phát phúc, lợi cho con cháu.'
    },
    {
        position: 64,
        tenque: 'HỎA THỦY VỊ TẾ',
        nguhanhgiadinhquai: 'HỎA',
        huyenkhongnguhanh: 3,
        huyenkhongquaivan: 9,
        thequai: 'TAM THẾ QUÁI',
        hoagiap: 'GIÁP THÂN',
        haothe: 3,
        haoung: 6,
        diachiquai1: 'Huynh Đệ',
        diachiquai2: 'Tỵ',
        diachiquai3: 'Tử Tôn',
        diachiquai4: 'Mùi',
        diachiquai5: 'Thê Tài',
        diachiquai6: 'Dậu',
        diachiquai7: 'Huynh Đệ',
        diachiquai8: 'Ngọ',
        diachiquai9: 'Tử Tôn',
        diachiquai10: 'Thìn',
        diachiquai11: 'Phụ Mẫu',
        diachiquai12: 'Dần',
        CodeQuai: 'Lien-Dut-Lien-Dut-Lien-Dut',
        note4: 'Thể',
        note1: 'Ứng',
        vieccanhoi:
            '<strong>XUẤT HÀNH:</strong> Đừng chờ đợi điều may mắn đến liền tay.<br><strong>SỰ NGHIỆP:</strong> Có nhiều thách thức khi bạn phải thích nghi môi trường mới.<br><strong>TÀI LỘC:</strong> Thị trường chưa chín nhưng tiềm ẩn nhiều cơ hội.<br><strong>KINH DOANH:</strong> Thời kỳ ích thích hợp cho lợi lộc. Hãy luôn cảnh giác.<br><strong>TÌNH CẢM:</strong> Có bất đồng vào lúc đầu nhưng miễn là bạn đối xử với người khác chân thành với trái tim cởi mở, một cái kết đẹp cho đám cưới hoàn hảo.<br><strong>HỌC HÀNH:</strong> Đừng sợ hãi. Cuộc thi của bạn không quá khó như bạn tưởng.<br><strong>SỨC KHỎE: </strong>Bệnh tình không ổn định, mất ngủ, bệnh về máu huyết. Cẩn trọng bệnh mất ngủ, bệnh truyền nhiễm hay bênh do gien di truyền như túi mật hay thận.<br>'
    }
];

export default ListQueKinhDich